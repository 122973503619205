import React, { useEffect } from 'react'
import logo from '../assets/logos/logoCabiar1.jpg'
import bg from '../assets/img/taxi-sign-at-night-e1635074452350.jpg'
import { Link } from 'react-router-dom' 
const Header = ({pg}) => {

    const openMenu = (e) => {
        e.stopPropagation();
        document.querySelector("#menu-header").classList.toggle("open-menu")

    }

    useEffect(()=>{
        document.querySelector("#btn-menu").addEventListener("click", openMenu)
        document.addEventListener("click", (e)=>{
            document.querySelector("#menu-header").classList.remove("open-menu")
        })
    },[])
    return (

        <div className="w-full ">

            <div className="bg-[#2A2A2A] h-[120px] flex justify-center items-center ">
                <div className="self-start flex-1 ml-[16rem] mt-[1rem] flex max-md:ml-5   items-center">
                    <div className="flex  items-center flex-1">
                        <i class="fa-solid   fa-envelope  text-[#213CC1]"></i>
                        <span className=' text-[#fff] ml-3 max-sm:text-[0.8rem]'>info@cabiar.com</span>
                    </div>
                
                    <div className='max-lg:hidden  font-header text-[#fff] flex-1'>Te ofrecemos<span className='color-cabiar'> la oportunidad de viajar con puntos </span>siempre.</div>
                </div>
                <div className="bg-[#fff] absolute top-[60px] w-[70%]  max-lg:w-[95%] rounded-[20px] flex  items-center px-7 py-4  " >
                <Link to="/"><img className='w-[90px] h-[90px] rounded-[20px] ml-4' src={logo} alt='' /></Link>
                    <div className='flex flex-1  items-center justify-center max-lg:hidden  '>
                        <Link to="/" className={`mx-5 text-base font-header font-[600] ${ pg ===1 && 'text-[#213CC1]'}`}>Inicio</Link>
                        <Link to="/about" className={`mx-5 text-base font-header  font-[600] ${ pg ===2 && 'text-[#213CC1]'}`}>Nosotros</Link>
                        <Link to="/services" className={`mx-5 text-base font-header  font-[600] ${ pg ===3 && 'text-[#213CC1]'}`}>Viaja</Link>
                        <a href="https://cabiarblog.blogspot.com/" target='_blank' className={`mx-5 text-base font-header  font-[600] ${ pg ===4 && 'text-[#213CC1]'}`}>Blog</a>
                        <Link to="/contact" className={`mx-5 text-base font-header  font-[600] ${ pg ===5 && 'text-[#213CC1]'}` }>Contacto</Link>
                    </div>
 
                    <Link to="/singup" className="btn-all max-lg:hidden  w-[190px] py-[25px] bg-[#213CC1] rounded-md text-center font-header font-[600] text-base text-[#fff]">¡Regístrate Ya!</Link>
                    <div id="btn-menu"  className='hidden  max-lg:flex flex-1 justify-end'>
                        <i class="cursor-pointer fa-solid fa-bars text-[30px]"></i>
                    </div>
                </div>


                <div id='menu-header' className="menu-movil z-[500]     bg-[#fff] fixed left-[-120%] bottom-0 top-[60px] w-[70%]   h-[100vh]   flex-col  items-start px-7 py-4 justify-start  " >
                    <img className='w-[90px] h-[90px] rounded-[20px] ml-4' src={logo} alt='' />
                    <div className='flex flex-1 flex-col items-start justify-start mt-8    '>
                        <Link to="/" className={`mx-5 px-5 py-5 text-[25px] font-header font-[600] ${ pg ===1 && 'text-[#213CC1]'}`}>Inicio</Link>
                        <Link to="/about" className={`mx-5 px-5 py-5 text-[25px] font-header  font-[600] ${ pg ===2 && 'text-[#213CC1]'}`}>Nosotros</Link>
                        <Link to="/services" className={`mx-5 px-5 py-5 text-[25px] font-header  font-[600] ${ pg ===3 && 'text-[#213CC1]'}`}>Viaja</Link>
                        <a href="https://cabiarblog.blogspot.com/" target='_blank'  className={`mx-5 px-5 py-5 text-[25px] font-header  font-[600] ${ pg ===4 && 'text-[#213CC1]'}`}>Blog</a>
                        <Link to="/contact" className={`mx-5 px-5 py-5 text-[25px] font-header  font-[600] ${ pg ===5 && 'text-[#213CC1]'}` }>Contacto</Link>
                    <Link to="/singup" className="btn-all    w-[190px] py-[25px] bg-[#213CC1] rounded-md text-center font-header font-[600] text-base text-[#fff]">¡Regístrate Ya!</Link>
                    </div>
 
                    
                </div>
            </div>

        </div>
    )
}

export default Header;