import React, { useState, useRef, memo, useEffect, useLayoutEffect } from "react";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
import Delete1 from '../../assets/img/delete-1.png'
import Delete2 from '../../assets/img/delete-2.png'
import Delete3 from '../../assets/img/delete-3.png'
 
 
 

const EliminarCuenta = (props) => {
    window.scrollTo(0,0); 
    return (
        <>
 
            <div style={{ flex: 1, backgroundColor: "#fff", }} >
            <Header  />
                <div style={{ flex: 1, padding: 20, marginTop: 77 }}>
                    <div style={{ flex: 1, marginBottom: 50 }}>
                      <div className="justify-center flex flex-col items-center">
                        <p className="titleP">Si desea eliminar tu cuenta sigue estos pasos</p>
                        <p  className="desc">Al eliminar tu cuenta todos tus datos se borraran, si decides eliminar eliminar bajo tu responsabilidad sigue estos pasos.</p>

                        <h1 className="titleD">1.Abre el menu y entra a Perfil </h1>
                        <img className="w-[220px]" src={Delete1} alt="" />
                        <h1 className="titleD">2.Luego presiona eliminar cuenta</h1>
                        <img className="w-[220px]" src={Delete2} alt="" />

                        <h1 className="titleD">3.Luego presiona presiona si en el dialogo de eliminar cuenta</h1>
                        <img className="w-[220px]" src={Delete3} alt="" />
                      </div>


                        
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
};

export default EliminarCuenta;
 