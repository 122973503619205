import React, { useEffect, useState } from 'react'
import logo from '../../assets/logos/logoCabiar1.jpg'
import bg from '../../assets/img/taxi-sign-at-night-e1635074452350.jpg'
import appimng from '../../assets/img/app3-ST8ZZE3-e1635131579605.png'
import riderimg from '../../assets/img/calling-taxi-e1635074462162-pf18t5veh4igp45apzdjwsc3r1x9hu53voy1wv69i0.jpg'
import taxi1 from '../../assets/img/taxi-car-on-the-city-street-e1635074489367.jpg'
import taxi2 from '../../assets/img/couple-on-a-taxi-in-manhattan-e1635074481719.jpg'
import aboutimg from '../../assets/img/trip-travel-and-vacations-concept-woman-with-yellow-suitcase-is-waiting-for-the-taxi-e1635074714722-pf18zqqqbdhrsqmo5s64pux57fhccj26ujd89jx1qq.jpg'
import aboutimg2 from '../../assets/img/female-traveler-trying-to-chatch-car-e1635074705543-pf18zia6lv66w8yyj6ihlf1zuyn1f94ltdhuy29laq.jpg'
import aboutimg3 from '../../assets/img/taxi-PNTQUQ5.png'
import fondoabout4 from '../../assets/img/1e97aa2d-5272-41ab-8f49-c226fd4b7013.jpg'
import app2 from '../../assets/img/app2-ST8ZZE3-e1635074601447.png'
import app3 from '../../assets/img/fodoazul.jpeg'
import app4 from '../../assets/img/4415922.jpg'
import teamimg1 from '../../assets/img/happy-man-smiling-with-hands-in-pocket-against-a-w-XA7YR8F.jpg'
import teamimg2 from '../../assets/img/portrait-of-happy-handsome-bearded-turkish-man-smi-L5DJ897.jpg'
import aboutimg5 from '../../assets/img/Banner_Ridesharer.jpg'
import servicesimg from '../../assets/img/young-asian-pretty-woman-waving-her-hand-on-the-road-pf0red5t4afdcakxrtm8mmym5nz8u7v7wvid5gy9k0.jpg'
import apple from '../../assets/img/app-store1.png'
import play from '../../assets/img/google-play-badge-e1630982322931.png'
import suportimg from '../../assets/img/indian-operator-assistant-using-a-microphone-headset-while-conversing-with-a-customer-e1635131565916-pf2e84hd1ng479qhvl4hsikf3ewxko9o7u4ezlvimo.jpg'
import { Link } from 'react-router-dom'
import Header from '../../common/Header'
import Footer from '../../common/Footer'
import '../../css/pulse.css'

import ProgressBar from './ProgressBar'
import FooterHead from '../../common/FooterHead'
let interval1 = null;
const Contact = () => {
    const [progressValue1, setProgressValue1] = useState(80);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    // useEffect(() => {
    //     interval1 = setInterval(() => {
    //         setProgressValue1((prevValue) => (  prevValue + 1  ));
    //     }, 80);

    //     return () => clearInterval(interval1);
    // }, []);


    // if(interval1 && progressValue1 >= 80){
    //     clearInterval(interval1)
    // }
    return (

        <div className="w-full bg-white ">

            <Header pg={5} />
            <div className="w-full h-[500px] bg-cover	  " style={{ background: `url(${bg})` }}>
                <div className="w-full h-[100%] Khula bg-[#000000a8] " >
                    <div className='flex-1  flex flex-col justify-center items-center  h-[100%] '>
                        <p className='text-[#fff] text-[60px] font-[700] max-sm:text-[40px]   '>
                            Contact<span className='color-cabiar'>.</span>
                        </p>
                        <p className='text-white'><span >Home</span> <i class="mx-3 color-cabiar text-[13px] fa-solid fa-greater-than"></i> <span>Contact</span></p>

                    </div>
                </div>
            </div>
            <div className='w-full flex    khula bg-[#fff] justify-center px-[15rem] max-xl:px-[3rem] max-lg:px-[5rem] items-center mt-[1rem] max-sm:mt-[4rem] mb-[7rem] flex-wrap'>
                <div className='flex flex-col flex-1 bg-[#fff] px-2   '>
                    <p className='color-cabiar text-[18px] font-[600]'>Contactanos</p>
                    <p className=' text-[50px] font-[700]'>Mantente en contacto<span className='color-cabiar'>.</span></p>
                    <p className='w-[90%] text-[1rem] text-left text-[#6d6d6d] max-sm:text-[0.9rem]   max-sm:w-[95%]'> Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum socis natoque penatibus. </p>
                    <div className=' border-solid border-[1px] border-[#eaeaea] w-[100%] self-start  my-[4rem] max-lg:w-[80%]'></div>
                    <div className='flex items-center'>
                        <p className='flex-1'></p>
                        <div className='flex-1 flex items-center justify-evenly'>
                            <a><i class="btn-social w-[48px] text-center    fa-brands fa-facebook-f cursor-pointer px-4 py-3 rounded-[7px] bg-[#213CC1] text-[#fff]"></i></a>
                            <a><i class="btn-social w-[48px] text-center fa-brands fa-instagram cursor-pointer px-4 py-3 rounded-[7px] bg-[#213CC1] text-[#fff]"></i></a>
                            <a><i class="btn-social w-[48px] text-center fa-brands fa-whatsapp cursor-pointer px-4 py-3 rounded-[7px] bg-[#213CC1] text-[#fff]"></i></a>
                            <a><i class="btn-social w-[48px] text-center fa-brands fa-youtube cursor-pointer px-4 py-3 rounded-[7px] bg-[#213CC1] text-[#fff]"></i></a>
                        </div>
                    </div>
                </div>
                <div className='flex-1 flex flex-col'>
                    <div className='flex-1 flex items-center flex-1 mt-4  px-7 py-7   cursor-pointer'>
                        <div className='  max-lg:w-[100%] max-sm:w-[100%] rounded-[20px] shadow-lg    px-12 py-10  flex-wrap'>
                            <div className='flex items-center flex-wrap flex-1'>
                                <div className='flex flex-col  mt-[1rem] flex-1 mr-[1rem] max-xl:mr-[0rem]   ' >
                                    <label className='label-inputs'>Nombre</label>
                                    <input className='inputs' placeholder='Tu nombre' />
                                </div>
                                <div className='flex flex-col  mt-[1rem] flex-1  ' >
                                    <label className='label-inputs'>Email</label>
                                    <input className='inputs' placeholder='Tu email' />
                                </div>
                            </div>

                            <div className='flex flex-col mt-[1rem] flex-1' >
                                <label className='label-inputs'>Sub Titulo</label>
                                <input className='inputs' placeholder='Sub Titulo' />
                            </div>

                            <div className='flex flex-col mt-[1rem]' >
                                <label className='label-inputs'>Mensaje</label>
                                <textarea className='inputs h-[150px]' placeholder='Tu mensaje' />
                            </div>
                            <div className='flex flex-col mt-[1rem]' >
                                <button className='khula btn-send rounded-[7px] bg-[#213CC1] px-[4rem] py-[1.8rem] text-[#fff]'>Enviar mensaje</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>



            <FooterHead />
            <Footer />
        </div>
    )
}

export default Contact;