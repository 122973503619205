
const FotoEquipo = ({ img, title, subtitle, instagram, youtube, facebook }) => {

    return (
        <div className='hover-equipo w-[50%]    max-lg:w-[100%] relative  mt-[2.3rem]   flex flex-col  h-[450px]    '  >
            <div className='hover-equipo w-[350px]  h-[450px] relative  max-lg:w-[100%]     flex flex-col  rounded-[12px]  bg-cover bg-no-repeat ' style={{ backgroundImage: `url(${img})` }} >
                {/* <div className=' relative  w-[100%] '>
                <img src={img} alt='' className='  w-[100%]    max-lg:w-[450px] bg-[#000]   object-cover rounded-[12px]' />
            </div> */}
                <div className=' absolute  bottom-[15px]  w-[240px] khula   h-fit  self-end right-[-10px] z-10 mx-[2rem] bg-[#2a2a2a] px-[30px] py-[15px] rounded-[15px]   flex flex-col justify-end items-end'>
                    <h2 className='text-[#fff] text-[20px] font-bold '>{title}</h2>
                    <h2 className='color-cabiar text-[18px] '>{subtitle}</h2>
                </div>
                <div className="  opacity-0  z-20 redes-equipo absolute w-full bg-[rgba(0,0,0,.7)] h-0 bottom-[0px]  rounded-[12px] flex flex-col items-center justify-center" >
                    <div className='flex-1 flex items-center justify-center w-full'>
                        <a href={facebook} target="_blank"><i class="btn-social w-[48px] text-center    fa-brands fa-facebook-f cursor-pointer px-4 py-3 rounded-[7px] bg-[#213CC1] text-[#fff]"></i></a>
                        <a href={instagram} target="_blank"><i class="mx-[1rem] btn-social w-[48px] text-center fa-brands fa-instagram cursor-pointer px-4 py-3 rounded-[7px] bg-[#213CC1] text-[#fff]"></i></a>
                        {/* <a><i class="btn-social w-[48px] text-center fa-brands fa-whatsapp cursor-pointer px-4 py-3 rounded-[7px] bg-[#213CC1] text-[#fff]"></i></a> */}
                        <a href={youtube} target="_blank"><i class="btn-social w-[48px] text-center fa-brands fa-youtube cursor-pointer px-4 py-3 rounded-[7px] bg-[#213CC1] text-[#fff]"></i></a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FotoEquipo;