import React, { useEffect, useRef, useState } from 'react'
import logo from '../../assets/logos/logoCabiar1.jpg'
import bg from '../../assets/img/taxi-sign-at-night-e1635074452350.jpg'
import appimng from '../../assets/img/app3-ST8ZZE3-e1635131579605.png'
import riderimg from '../../assets/img/calling-taxi-e1635074462162-pf18t5veh4igp45apzdjwsc3r1x9hu53voy1wv69i0.jpg'
import taxi1 from '../../assets/img/taxi-car-on-the-city-street-e1635074489367.jpg'
import taxi2 from '../../assets/img/couple-on-a-taxi-in-manhattan-e1635074481719.jpg'
import aboutimg from '../../assets/img/trip-travel-and-vacations-concept-woman-with-yellow-suitcase-is-waiting-for-the-taxi-e1635074714722-pf18zqqqbdhrsqmo5s64pux57fhccj26ujd89jx1qq.jpg'
import aboutimg2 from '../../assets/img/female-traveler-trying-to-chatch-car-e1635074705543-pf18zia6lv66w8yyj6ihlf1zuyn1f94ltdhuy29laq.jpg'
import aboutimg3 from '../../assets/img/taxi-PNTQUQ5.png'
import fondoabout4 from '../../assets/img/1e97aa2d-5272-41ab-8f49-c226fd4b7013.jpg'
import app2 from '../../assets/img/app2-ST8ZZE3-e1635074601447.png'
import app3 from '../../assets/img/fodoazul.jpeg'
import app4 from '../../assets/img/4415922.jpg'
import teamimg1 from '../../assets/img/happy-man-smiling-with-hands-in-pocket-against-a-w-XA7YR8F.jpg'
import teamimg2 from '../../assets/img/portrait-of-happy-handsome-bearded-turkish-man-smi-L5DJ897.jpg'
import aboutimg5 from '../../assets/img/Banner_Ridesharer.jpg'
import servicesimg from '../../assets/img/young-asian-pretty-woman-waving-her-hand-on-the-road-pf0red5t4afdcakxrtm8mmym5nz8u7v7wvid5gy9k0.jpg'
import apple from '../../assets/img/app-store1.png'
import play from '../../assets/img/google-play-badge-e1630982322931.png'
import suportimg from '../../assets/img/indian-operator-assistant-using-a-microphone-headset-while-conversing-with-a-customer-e1635131565916-pf2e84hd1ng479qhvl4hsikf3ewxko9o7u4ezlvimo.jpg'
import { Link, useLocation } from 'react-router-dom'
import Header from '../../common/Header'
import Footer from '../../common/Footer'
import '../../css/pulse.css'

import ProgressBar from './ProgressBar'
import FooterHead from '../../common/FooterHead'
import Loading from '../../common/Loading'
import axios from 'axios'
import { useQuery } from '../../hooks/useQuery'
import ConstantsApp from '../../Constants/ConstantsApp'
import lib from '../../lib/index.js'
import Modal from '../../common/Modal'
import app_store from '../../assets/logos/app_store_es.png'
import play_store from '../../assets/logos/play_store_es.png'
import ModalHeader from '../../common/ModalHeader'
let interval1 = null;
const RegistroCabiar = () => {
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [countrys, setCountrys] = useState([]);
    const nombre_ref = useRef();
    const apellido_ref = useRef();
    const email_ref = useRef();
    const tel_ref = useRef();
    const invite_code_ref = useRef();
    const user_type_ref = useRef();
    const user_parent_type_ref = useRef();
    const country_ref = useRef();
    const modal_ref = useRef();
    const [type, setType] = useState(['Driver', 'Rider'])
    const query = useQuery();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    useEffect(() => {

        const invite = query.get('inv');
        const user_type = query.get('type');
        user_parent_type_ref.current = user_type;
        invite_code_ref.current.value = invite;
        user_type_ref.current.value = user_type;

        if (user_type?.toUpperCase() === 'DRIVER') {
            setType(['Driver', 'Rider'])
        }
        else if (user_type?.toUpperCase() === 'RIDER') {
            setType(['Rider'])
        } else {
            setType(['Driver', 'Rider'])
        }
    }, [])


    const limpiarCampos = () => {
        nombre_ref.current.value = "";
        apellido_ref.current.value = "";
        email_ref.current.value = "";
        tel_ref.current.value = "";
        invite_code_ref.current.value = "";
        user_type_ref.current.value = "Rider";
    }
    function separarLetrasYNumeros(cadena) {
        // Usamos una expresión regular para encontrar letras y números
        const letras = cadena.match(/[a-zA-Z]+/g);
        const numeros = cadena.match(/\d+/g);

        // Si hay letras, las convertimos en un solo string
        const letrasSeparadas = letras ? letras.join('') : '';

        // Si hay números, los convertimos en un solo string
        const numerosSeparados = numeros ? numeros.join('') : '';

        return { letras: letrasSeparadas, numeros: numerosSeparados };
    }


    const validateNumber = (text) => { 
        return text.match( /^[0-9]+$/) 
      }
    const singup = async (e) => {
        if (!loading) {
            setLoading(true)
            const url = `${process.env.REACT_APP_SOCKET}/singup-us`;
            const email_regex = new RegExp(ConstantsApp.EMAIL_REGEX)
            let ok = true;
            if (nombre_ref.current?.value.trim() === "") {
                setError("Debe llenar su nombre");
                ok = false;
            }

            if (apellido_ref.current?.value.trim() === "") {
                setError("Debe llenar su apellido");
                ok = false;
            }
            if (!validateNumber(tel_ref.current?.value)) {
                setError("Ingrese un número de teléfono valido");
                ok = false;
              }
         

            if (country_ref.current.value === null) {
                setError("Ingrese su páis");
                ok = false;
            }            
            
            if (user_type_ref.current.value === null || user_type_ref.current.value === "") {
                setError("Ingrese su tipo de usuario");
                ok = false;
            }

            console.log("user_type_ref.current  ",user_type_ref.current.value)
            if (!email_regex.test(email_ref.current.value?.trim())) {
                setError("Ingrese un email valido");
                ok = false;
            }

            console.log("code", user_type_ref.current.value)
            if( invite_code_ref?.current?.value === ''  && user_type_ref.current.value === 'Driver' ){
                setError("Para registrarse como conductor debes tener un código de invitación");
                ok = false;
            }
            if (ok) {
                setError("");
                const country = countrys.find(x => x.id === parseInt(country_ref.current.value))
                const data = {
                    nombre: nombre_ref.current?.value,
                    apellido: apellido_ref.current?.value,
                    email: email_ref.current?.value,
                    tel: tel_ref.current?.value,
                    invite_code: invite_code_ref?.current?.value,
                    user_type: user_type_ref.current?.value,
                    parent_type: user_parent_type_ref.current,
                    currency: country.currency,
                    country: country.id,
                    language: country.language,
                    country_iso3: country.iso3,
                    short_name: country.short_name,
                    phone_code: country.phone_code
                }
                console.log(data)
                const encrypted_data = lib.encrypter(data, process.env.REACT_APP_KEYCRYP);
                try {
                    const res = await axios.post(url, { data: encrypted_data }, { headers: { "api-cb": process.env.REACT_APP_API } });
                    if (res.status === 200) {
                        if (res.data.ok) {
                            openModal()
                            limpiarCampos();
                        } else {
                            alert("No pudimos crear su usuario, porque ya existe")
                            setError("No pudimos crear su usuario, porque ya existe");
                        }
                    }
                } catch (error) {
                    console.log(error)
                } finally {
                    setLoading(false)
                }
            } else {
                setLoading(false)
            }
        }
    }

    const getCountrys = async () => {
        try {
            const url = `${process.env.REACT_APP_SOCKET}/countrys`;
            const res = await axios.post(url, {}, { headers: { "api-cb": process.env.REACT_APP_API } });
            if (res.status === 200) {
                setCountrys(res.data)
            }
        } catch (error) {

        }
    }

    useEffect(() => {
        getCountrys();
    }, [])

    const closeModal = () => {
        modal_ref.current.classList.remove("open-modal");
        modal_ref.current.classList.toggle("close-modal");
    }
    const openModal = () => {
        modal_ref.current.classList.toggle("open-modal");
    }
    return (

        <div className="w-full bg-white ">

            <Header />
            {/* <div className="w-full h-[500px] bg-cover	  " style={{ background: `url(${bg})` }}>
                <div className="w-full h-[100%] Khula bg-[#000000a8] " >
                    <div className='flex-1  flex flex-col justify-center items-center  h-[100%] '>
                        <p className='text-[#fff] text-[60px] font-[700] max-sm:text-[40px]   '>
                            Contact<span className='color-cabiar'>.</span>
                        </p>
                        <p className='text-white'><span >Home</span> <i class="mx-3 color-cabiar text-[13px] fa-solid fa-greater-than"></i> <span>Contact</span></p>

                    </div>
                </div>
            </div> */}

            <div className='mt-[12em] w-full flex flex-col   khula bg-[#fff] justify-center px-[15rem]    max-xl:px-[3rem] max-lg:px-[0.8rem] items-center mt-[1rem] max-sm:mt-[4rem] mb-[7rem] flex-wrap'>
                <div className='flex flex-col flex-1 bg-[#fff] px-2   '>
                    <p className='color-cabiar text-[18px] font-[600]'>Regístro</p>

                    <p className=' text-[50px] font-[700]'>Llena tus datos<span className='color-cabiar'>.</span></p>
                    {/* <p className='w-[90%] text-[1rem] text-left text-[#6d6d6d] max-sm:text-[0.9rem]   max-sm:w-[95%]'> Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum socis natoque penatibus. </p> */}

                </div>
                <div className='flex-1 flex flex-col w-full justify-center items-center' >
                    {/* <marker id="circle" markerWidth="8" markerHeight="8" refX="5" refY="5">ddsdasdasd</marker> */}
                    <div className='  flex justify-center items-center flex-1 mt-4  px-7 py-7  max-sm:px-[1rem] max-sm:w-full cursor-pointer'>
                        <div className='  max-lg:w-[100%] max-sm:w-[100%] rounded-[20px] shadow-lg     px-12 py-10  flex-wrap'>
                            <div className='flex items-center flex-wrap flex-1'>
                                <div className='flex flex-col  mt-[1rem] flex-1 mr-[1rem] max-xl:mr-[0rem]   ' >
                                    <label className='label-inputs'>Nombre</label>
                                    <input ref={nombre_ref} className='inputs' placeholder='Tu nombre' />
                                </div>
                                <div className='flex flex-col  mt-[1rem] flex-1  ' >
                                    <label className='label-inputs'>Apellido</label>
                                    <input ref={apellido_ref} className='inputs' placeholder='Tu Apellido' />
                                </div>
                            </div>
                            <div className='flex flex-col  mt-[1rem] flex-1  ' >
                                <label className='label-inputs'>Email</label>
                                <input ref={email_ref} className='inputs' placeholder='Tu email' />
                            </div>
                            <div className='flex flex-col  mt-[1rem] flex-1  ' >
                                <label className='label-inputs'>País</label>
                                <select ref={country_ref} className='inputs'    >
                                    {countrys.map((item) => (<option key={item.id} value={item.id}>{item.long_name}</option>))}
                                </select>
                            </div>
                            <div className='flex flex-col mt-[1rem] flex-1' >
                                <label className='label-inputs'>Teléfono </label>
                                <input ref={tel_ref} className='inputs' placeholder='Teléfono' />
                            </div>
                                
                            {  
                            <div className={`${!query.get('inv') && 'hidden'} flex flex-col mt-[1rem] flex-1`} >
                                <label className='label-inputs'>Código de invitación</label>
                                <input ref={invite_code_ref} className='inputs' placeholder='Códio de invitación' />
                            </div>
                            }
                            {type.length > 0 &&
                                <div className='flex flex-col mt-[1rem] flex-1' >
                                    <label className='label-inputs'>Tipo</label>
                                    <select ref={user_type_ref} className='inputs'   >
                                        {type.map((item) => (<option value={item}>{item === "Rider" ? 'Pasajero' : 'Conductor'}</option>))}
                                    </select>
                                </div>}
                            <div className='my-[2rem] text-center'>
                                <label className=' text-[#ff0000] font-[600]'>{error}</label>
                            </div>
                            <div className='flex mt-[1rem] items-center justify-center' >
                                <button onClick={singup} type='button' className='flex items-center khula btn-send rounded-[7px] bg-[#213CC1] px-[4rem] py-[1.1rem] text-[#fff]'>
                                    {loading ? <><Loading /> Procesando</> : "Regístrate"}
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
                <div className=' border-solid border-[1px] border-[#eaeaea] w-[100%] self-start  my-[4rem] max-lg:w-[80%]'></div>
                <div className='flex items-center w-full'>
                    <p className='flex-1'>Social Media :</p>
                    <div className='flex-1 flex items-center justify-evenly'>
                        <a><i class="btn-social fa-brands fa-facebook-f cursor-pointer px-4 py-3 rounded-[7px] bg-[#213CC1] text-[#fff]"></i></a>
                        <a><i class="btn-social fa-brands fa-instagram cursor-pointer px-4 py-3 rounded-[7px] bg-[#213CC1] text-[#fff]"></i></a>
                        <a><i class="btn-social fa-brands fa-whatsapp cursor-pointer px-4 py-3 rounded-[7px] bg-[#213CC1] text-[#fff]"></i></a>
                        <a><i class="btn-social fa-brands fa-youtube cursor-pointer px-4 py-3 rounded-[7px] bg-[#213CC1] text-[#fff]"></i></a>
                    </div>
                </div>
            </div>


            <Modal ref={modal_ref} className="flex items-center justify-center flex-col"   >
                <div className='flex justify-start flex-col bg-white px-12 pt-7 pb-8 rounded-md'>
                    <ModalHeader onClose={closeModal} title="Siguiente paso" />
                    <div className="flex items-center justify-start flex-col pt-8 w-full ">
                        <p className='pb-5 mt-2 font-bold   text-[#000] text-[1.2rem] '>¡Tu usario se ha creado con exito!</p>
                        <p className='pb-5 mt-2 font-[600]   text-[#00000099] text-[1.2rem] '>Descargala en las tiendas, seleciona la tienda de tu dispositivo móvil,<br /> luego instala Cabiar, inicia sesión y sigue los pasos.</p>
                        <div className='flex justify-between flex-row bg-white mt-2'>
                            <a target='blank' href='https://apps.apple.com/us/app/cabiar/id6472674617'>
                                <img src={app_store} className='icon-store w-[150px] mr-5' alt="" /> 
                            </a>
                            <a target='blank' href='https://play.google.com/store/apps/details?id=com.jdrm.Cabiar'> 
                                <img src={play_store} className='icon-store w-[150px]' alt="" />
                            </a>
                        </div>
                    </div>
                </div>
            </Modal>
            <FooterHead />
            <Footer />
        </div>
    )
}

export default RegistroCabiar;