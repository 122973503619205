 
const ICON_antdesign = "antdesign";
const ICON_entypo = "entypo";
const ICON_evilicon = "evilicon";
const ICON_feather = "feather";
const ICON_font_awesome5 = "font-awesome-5"; 
const ICON_font_awesome = "font-awesome"; 
const ICON_fontisto = "fontisto";
const ICON_foundation = "foundation";
const ICON_ionicon = "ionicon";
const ICON_material_community = "material-community";
const ICON_material = "material"; 
const ICON_octicon = "octicon";
const ICON_simple = "simple-line-icon"; 
const ICON_zocial = "zocial";
export default {
    ICON_antdesign: ICON_antdesign,
    ICON_entypo: ICON_entypo,
    ICON_evilicon: ICON_evilicon,
    ICON_feather: ICON_feather,
    ICON_font_awesome5: ICON_font_awesome5,
    ICON_font_awesome: ICON_font_awesome,
    ICON_fontisto: ICON_fontisto,
    ICON_foundation: ICON_foundation,
    ICON_ionicon: ICON_ionicon,
    ICON_material_community: ICON_material_community,
    ICON_material: ICON_material,
    ICON_octicon: ICON_octicon,
    ICON_simple: ICON_simple,
    ICON_zocial: ICON_zocial,
    EMAIL_REGEX:/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i

    
}