import React, { forwardRef } from 'react';
import closeIcon from '../assets/icons/close.png'
const ModalHeader = forwardRef((props, ref) => {
    const {
        title,
        children,
        className,
        onClose
    } = props;

    return (
        <div ref={ref} className={`${className} modal-header`}>
            <div className="flex items-center justify-evenly">
                <div className="modal-title text-[1.5rem] font-bold flex-1 ">{title}  </div>
                <img onClick={()=>onClose()} src={closeIcon} className='w-[25px] ml-5 cursor-pointer' alt=''  /> 
            </div>
            {children}
        </div>
    )

})

export default ModalHeader;
