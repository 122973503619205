import React from 'react' 
const ServiceType = () => {

    return (
        <div className='w-full flex flex-col  khula bg-[#fff] justify-center px-[10rem] max-lg:px-[2rem]  items-center mt-[1rem] max-sm:mt-[4rem] mb-[7rem] flex-wrap'>
            <div className='flex flex-col  bg-[#fff] justify-center  items-center '>
                <p className='color-cabiar text-[18px] font-[600]'>Nuestros Servicios</p>
                <p className=' text-[50px] font-[700] max-lg:text-[25px]'>Tus beneficios<span className='color-cabiar'>.</span></p>
                <p className='w-[70%] text-[1rem] text-center text-[#6d6d6d] max-sm:text-[0.9rem]   max-sm:w-[95%]'>Cabiar te ofrece beneficios únicos que también podrás compartir con otros. </p>
            </div>

            <div className='flex items-center justify-evenly flex-wrap'>
                <div className='card-services w-[500px] max-lg:w-[300px] bg-[#2a2a2a] mt-9 shadow-md px-7 py-7 rounded-[20px]  card-services'>
                    <i class="text-[#fff] fa-regular fa-credit-card self-start text-[30px] py-4 px-6 bg-[#213CC1] rounded-md"></i>
                    <p className='mt-5 font-[600] text-[#fff] text-[20px] max-sm:text-[17px]'>Cabiar REWARDS</p>
                    <p className='mt-3 text-[#fff]'>Gana puntos en cada viaje y usalos para pagar tus viajes futuros.</p>
                    {/* <div className='mt-3 text-[#fff] font-[600] cursor-pointer'>Learn More</div> */}
                </div>
                <div className='card-services w-[500px] max-lg:w-[300px] mt-9 shadow-md px-7 py-7 rounded-[20px]    card-services'>
                    <i class="text-[#fff] fa-solid fa-shield-halved self-start text-[30px] py-4 px-6 bg-[#213CC1] rounded-md"></i>
                    <p className='mt-5 font-[600] text-[#000] text-[20px] max-sm:text-[17px]'>Seguridad GARANTIZADA</p>
                    <p className='mt-3 text-[#6d6d6d]'>Cabiar te ofrece la aplicación mas segura en cada viaje.</p>
                    {/* <div className='mt-3 text-[#000] font-[600] cursor-pointer'>Learn More</div> */}
                </div>
                <div className='w-[500px] max-lg:w-[300px] mt-9 shadow-md px-7 py-7 rounded-[20px]    card-services'>
                    <i class="text-[#fff] fa-solid fa-solid fa-location-arrow self-start text-[30px] py-4 px-6 bg-[#213CC1] rounded-md"></i>
                    <p className='mt-5 font-[600] text-[#000] text-[20px] max-sm:text-[17px]'>Crea tu RED DE VIAJE</p>
                    <p className='mt-3 text-[#6d6d6d]'>Ganas puntos en cada viaje de las personas registradas en Cabiar a travéz de tu invitación.</p>
                    {/* <div className='mt-3 text-[#000] font-[600] cursor-pointer'>Learn More</div> */}
                </div>
                <div className='w-[500px] max-lg:w-[300px] mt-9 shadow-md px-7 py-7 rounded-[20px]    card-services'>
                    <i class="text-[#fff] fa-solid fa-pencil self-start text-[30px] py-4 px-6 bg-[#213CC1] rounded-md"></i>
                    <p className='mt-5 font-[600] text-[#000] text-[20px] max-sm:text-[17px]'>Viaja a Cualquier DESTINO</p>
                    <p className='mt-3 text-[#6d6d6d]'>Viaja a donde gustes y a cualqier distancia; Cabiar te lleva.</p>
                    {/* <div className='mt-3 text-[#000] font-[600] cursor-pointer'>Learn More</div> */}
                </div>

                <div className='w-[500px] max-lg:w-[300px] mt-9 shadow-md px-7 py-7 rounded-[20px]    card-services'>
                    <i class="text-[#fff] fa-solid fa-medal self-start text-[30px] py-4 px-6 bg-[#213CC1] rounded-md"></i>
                    <p className='mt-5 font-[600] text-[#000] text-[20px] max-sm:text-[17px]'>Conductores DEPURADOS</p>
                    <p className='mt-3 text-[#6d6d6d]'>Cabiar revisa la trayectoria de sus conductores para el beneficio de nuestro pasajeros.</p>
                    {/* <div className='mt-3 text-[#000] font-[600] cursor-pointer'>Learn More</div> */}
                </div>
                <div className='w-[500px] max-lg:w-[300px] mt-9 shadow-md px-7 py-7 rounded-[20px]    card-services'>
                    <i class="text-[#fff] fa-solid fa-tags self-start text-[30px] py-4 px-6 bg-[#213CC1] rounded-md"></i>
                    <p className='mt-5 font-[600] text-[#000] text-[20px] max-sm:text-[17px]'>Descuentos y PROMOCIONES</p>
                    <p className='mt-3 text-[#6d6d6d]'>Disfruta de descuentos y promociones todos los meses obteniendo el doble o más de puntos en cada viaje.</p>
                    {/* <div className='mt-3 text-[#000] font-[600] cursor-pointer'>Learn More</div> */}
                </div>
            </div>
        </div>
    )
}

export default ServiceType;