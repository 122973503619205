import React, { useState, useRef, memo, useEffect, useLayoutEffect } from "react";
import Footer from "../../common/Footer";
import Header from "../../common/Header";



const PoliticaDePrivacidad = memo((props) => {
    window.scrollTo(0, 0);

    return (


        <div style={{ flex: 1, backgroundColor: "#fff", }} >
            <Header />
            <div style={{ flex: 1, padding: 20, marginTop: 77 }}>
                <div style={{ flex: 1, marginBottom: 50 }}>
                    <p className="titleP" >Política de privacidad </p>
                    <p className="desc" >El presente Política de Privacidad establece los términos en que Cabiar usa y protege la información que es
                        proporcionada por sus usuarios al momento de utilizar su App. Esta compañía está comprometida con
                        la seguridad de los datos de sus usuarios. Cuando le pedimos llenar los campos de información personal
                        con la cual usted pueda ser identificado, lo hacemos asegurando que sólo se empleará de acuerdo con
                        los términos de este documento. Sin embargo, esta Política de Privacidad puede cambiar con el tiempo
                        o ser actualizada por lo que le recomendamos y enfatizamos revisar continuamente esta página para
                        asegurarse que está de acuerdo con dichos cambios.</p>

                    <p className="titleD" >Información que es recogida</p>
                    <p className="desc">Nuestra App podrá recoger información personal, por ejemplo: Nombre, información de contacto como su dirección de correo
                        electrónica e información demográfica, información sobre su vehículo.  Así mismo cuando sea necesario podrá ser requerida información específica para
                        procesar su solicitud para conducir y generar ganancias, en caso de solo ser pasajero solo se pedirá información personal y demográfica.
                        Se necesita que el usuario otorgue permiso de ubicación en primer plano para usar su ubicación actual para encontrar un viaje
                        y el conductor más cercano con relación a su ubicación
                        y solo se usara cuando (la app este abierta y en pantalla).
                        Se necesita que el usuario otorgue permiso de ubicación en segundo plano, para que su ubicación se envié a los pasajeros más cercanos y así recibir viajes y poder asignar a
                        los pasajeros el conductor más cercano, estos datos de ubicación se puede indicar con un icono o una notificación en el dispositivo móvil, según el sistema operativo,
                        solo se usara la ubicación en segundo plano cuando el usuario sea un conductor aprobado, cuando (la app este abierta, pero no en pantalla),
                        el usuario podrá activar y desactivar en cualquier momento, la ubicación en segundo plano.


                    </p>
                    {/* <p  className="titleD" >Cookies</p>
                        <p  className="desc" >
                            Las cookies son pequeños archivos de po que los sitios web, apps, medios en línea y anuncios almacenan
                            en el navegador o en el dispositivo. Cabiar usa cookies   para: autenticar usuarios, recordar
                            las preferencias y configuraciones del usuario

                        </p> */}

                    <p className="titleD" >Uso de la información introducida </p>
                    <p className="desc" >Nuestra App emplea la información con el fin de proporcionar el mejor servicio posible,
                        pero no utilizamos los datos de los usarios para hacer tracking, ni vender ni deistribuir información personal,
                        particularmente para mantener un registro de usuarios, de viajes y mejorar nuestros productos y servicios.
                        Es posible que sean enviados correos electrónicos periódicamente a través de nuestro sitio con ofertas especiales, nuevos productos
                        y otra información publicitaria que consideremos relevante para usted o que pueda brindarle algún beneficio, estos correos electrónicos
                        serán enviados a la dirección que usted proporcione y podrán ser cancelados en cualquier momento.
                        Cabiar está altamente comprometido para cumplir con el compromiso de mantener su información segura. Usamos los sistemas más avanzados y
                        los actualizamos constantemente para asegurarnos que no exista ningún acceso no autorizado.

                    </p>

                    <p className="titleD" >Control de su información personal </p>
                    <p className="desc" >Nuestra aplicación no recopila datos de seguimiento o tracking de los usuarios en sus redes sociales para rastrear sus actividades en otras empresas, aplicaciones y sitios web.
                        Esta compañía no venderá, cederá ni distribuirá la información personal que es introducida en nuestra aplicación.
                        Cabiar Se reserva el derecho de cambiar los términos de la presente Política de Privacidad en cualquier momento. 
                    </p>


                </div>
            </div>
            <Footer />
        </div>

    );
});

export default PoliticaDePrivacidad;

const styles = {


    titleP: {
        fontFamily: "Roboto-Medium",
        fontSize: 22,
        marginTop: 10
    },
    desc: {
        fontSize: 18,
        texta: "justify"
    },
    titleD: {
        fontFamily: "Roboto-Medium",
        fontSize: 18,
        marginTop: 10
    }
};