import React, { forwardRef } from 'react'
const Modal = forwardRef((props, ref) => {
    const {
        title,
        subtitle,
        children,
        className
    } = props;

    return (
        <div ref={ref} className={`${className} modal`}>
            <div className="modal-title">{title}</div>
            <div className="modal-subtitle">{subtitle}</div>
            {children}
        </div>
    )

})

export default Modal;
