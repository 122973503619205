import React from 'react'
import leftfondo from '../../assets/img/AdobeStock_524087524.jpeg'
import rigthfondo from '../../assets/img/AdobeStock_558107599.jpeg'
import ProgressBar from './ProgressBar'
import { Link } from 'react-router-dom'
const ServiceCard = () => {

    return ( 
        <div className='w-full flex  bg-[#fff]   items-center mt-[5rem] max-sm:mt-[4rem] mb-[8rem] flex-wrap'>

            <div className='w-[50%] h-[564px] bg-[length:100%_100%]  max-lg:w-[100%]  max-xl:w-[100%]' style={{ backgroundImage: `url(${rigthfondo})` }}>
                <div className='bg-[#2a2a2ab0] h-[564px] py-16 px-20 max-lg:px-[7px] max-lg:py-[25px]'>
                    <div className="ml-12  ">
                        <p className='text-[1.1rem] color-cabiar font-[700]'>Nuestro Compromiso </p>
                        <p className='text-[2.3rem] text-[#fff] font-[700] pr-8  max-sm:w-[95%] max-sm:text-[1.3rem]  line-h-title'>Nuestro compromiso empresarial<span className='color-cabiar'>.</span></p>
                        <p className='text-[0.9rem] text-[#fff] max-sm:text-[0.9rem]   max-sm:w-[95%]'>Nuestro objectivo principal es mejorar continuamente nuestro servicios, nuestro precios, nuestra tecnología y nuestros vehículos.</p>
                    </div>
                    <div className='ml-12 mt-4 flex flex-col flex-1'>
                        <p className='text-[1.1rem] text-[#fff] max-sm:text-[0.9rem] font-[600] mb-2  '>Calidad</p>
                        <ProgressBar value={98} maxValue={100} initial={98} />
                    </div>
                    <div className='ml-12 mt-4 flex flex-col flex-1'>
                        <p className='text-[1.1rem] text-[#fff] max-sm:text-[0.9rem] font-[600] mb-2  '>Profesionalismo</p>
                        <ProgressBar value={97} maxValue={100} initial={97} />
                    </div>
                    <div className='ml-12 mt-4 flex flex-col flex-1'>
                        <p className='text-[1.1rem] text-[#fff] max-sm:text-[0.9rem] font-[600] mb-2  '>Precios competitivo</p>
                        <ProgressBar value={99} maxValue={100} initial={99} />
                    </div>
                    <div className='ml-12 mt-4 flex flex-col flex-1'>
                        <p className='text-[1.1rem] text-[#fff] max-sm:text-[0.9rem] font-[600] mb-2  '>Tecnología</p>
                        <ProgressBar value={100} maxValue={100} initial={100} />
                    </div>
                </div>
            </div>
            <div className='w-[50%] h-[564px] bg-[length:100%_100%] khula  max-lg:w-[100%] max-xl:w-[100%]' style={{ backgroundImage: `url(${leftfondo})` }}>
                <div className='bg-[#00000040] h-[564px] relative '>
                    <div className=' absolute shadow-xl   max-lg:w-[90%]  px-7 py-7 bottom-[-40px]   right-[0px] w-[50%]     bg-[#fff] rounded-[18px]'>
                        <p className='text-[#000] font-[600] text-[1.5rem] mb-2  '>Ganas puntos mientras viajas.</p>
                        <p className=' text-[18px] text-[#6D6D6D] max-sm:text-[14px]  '>Acumulas puntos mientras viajas y mientras viajan las personas registradas en Cabiar a través de tu código único de invitación.</p>
                        <Link to="/singup" className='font-[700] mt-10 color-cabiar '>Cabiar contigo...</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServiceCard;
