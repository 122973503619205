import React, { useEffect, useState, useRef } from 'react'
import logo from '../../assets/logos/logoCabiar1.jpg'
import bg from '../../assets/img/taxi-sign-at-night-e1635074452350.jpg'
import appimng from '../../assets/img/app3-ST8ZZE3-e1635131579605.png'
import riderimg from '../../assets/img/calling-taxi-e1635074462162-pf18t5veh4igp45apzdjwsc3r1x9hu53voy1wv69i0.jpg'
import taxi1 from '../../assets/img/taxi-car-on-the-city-street-e1635074489367.jpg'
import taxi2 from '../../assets/img/couple-on-a-taxi-in-manhattan-e1635074481719.jpg'
import app2 from '../../assets/img/08.png'
import app3 from '../../assets/img/fodoazul.jpeg'
import fondohome from '../../assets/img/PasajeraConductor.jpg'
import app4 from '../../assets/img/4415922.jpg'
import apple from '../../assets/img/app-store1.png'
import play from '../../assets/img/google-play-badge-e1630982322931.png'
import suportimg from '../../assets/img/indian-operator-assistant-using-a-microphone-headset-while-conversing-with-a-customer-e1635131565916-pf2e84hd1ng479qhvl4hsikf3ewxko9o7u4ezlvimo.jpg'
import appimg2 from '../../assets/img/01.png'
import { Link } from 'react-router-dom'
import Header from '../../common/Header'
import Footer from '../../common/Footer'

import '../../css/pulse.css'


import FooterHead from '../../common/FooterHead'
import ServiceCard from './ServiceCard'
import ServiceType from './ServiceType'
import Modal from '../../common/Modal'
import ModalHeader from '../../common/ModalHeader'
let interval1 = null;
const Home = () => {

    const [progressValue1, setProgressValue1] = useState(80);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const modal_ref = useRef();
    const closeModal = () => {
        modal_ref.current.classList.remove("open-modal");
        modal_ref.current.classList.toggle("close-modal");
    }
    const openModal = () => {
        modal_ref.current.classList.toggle("open-modal");
    }
    // useEffect(() => {
    //     interval1 = setInterval(() => {
    //         setProgressValue1((prevValue) => (  prevValue + 1  ));
    //     }, 80);

    //     return () => clearInterval(interval1);
    // }, []);


    // if(interval1 && progressValue1 >= 80){
    //     clearInterval(interval1)
    // }
    return (

        <div className="w-full bg-white ">

            <Header pg={1} />
            <div className="w-full h-[800px] bg-cover max-sm:h-[900px]	  " style={{ backgroundImage: `url(${fondohome})` }}>
                <div className="w-full h-[200vh] Khula bg-[#000000a8] " >
                    <div className=' top-[250px] flex flex-col justify-center  items-center '>
                        <div className='mt-[150px]  w-[70%] max-sm:w-[100%]  max-xl:w-[100%] px-7 py-7 flex items-center  justify-center flex-wrap '>
                            <div className='flex-1 max-sm:w-[100%] max-sm:flex-none'>
                                <p className='color-cabiar mb-5 '>Tu red de transporte</p>
                                <p className='text-[#fff] text-[60px] font-[700] max-sm:text-[40px] line-h-title  '>
                                    Disfruta de un viaje seguro y cómodo<span className='color-cabiar'>.</span>
                                </p>
                                <p className='text-[#fff] text-base  '>
                                    Crea tu red de transporte invitando a tus conocidos a viajar con Cabiar y gana en cada unos de sus viajes.
                                </p>
                                <div className='flex   items-center mt-10'>

                                    <Link to="/singup" className="btn-home w-[190px] mr-[1rem] border-solid border-2 py-[20px]   rounded-md text-center font-header font-[600] text-base text-[#fff]">
                                        ¡Registrate aquí!
                                    </Link>
                                    <div onClick={() => openModal()} class="cursor-pointer  blob blue w-[60px] h-[60px] text-center flex flex-col justify-center items-center">
                                        <i class=" fa-solid fa-play text-[#fff] text-[25px] mr-[-4px]"></i>
                                    </div>
                                    <div className='ml-1'>

                                        <p className='Khula text-[#fff] text-[20px]'>Reproducir Video</p>

                                    </div>
                                </div>
                            </div>
                            <div className='flex-1 flex ml-20  max-sm:flex-none items-center justify-center'>
                                <img src={appimg2} className='w-[320px] rounded-xl shadow-xl  max-sm:w-[250px] max-sm:mt-7 max-md:w-[300px]' alt='' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='w-full bg-[#fff] khula flex justify-center items-center max-lg:mt-[5rem] '>
                <div className='w-[100%] flex flex-col justify-center items-center'>

                    <p className='font-[700] mt-40 text-[29px]'>¿Qué ofrecemos?</p>
                    <p className='w-[50%] max-lg:w-[80%] max-sm:w-[95%] pt-4 pb-4 text-[18px] text-center text-[#6D6D6D]'>En Cabiar acepatmos todos los métodos de pagos,
                        incluyendo tarjetas de crédito/débito, efectivo y nuestra Cabiar Wallet
                        que se nutre de los canjeos de los puntos que ganas en tus viajes y en los viajes de las personas que
                        se registraron en Cabiar a través de tu invitación. Para Cabiar la seguridad es primero,
                        es por eso que realizamos una intensa depuración de cada uno de nuestros conductores.</p>
                    <p className='mt-20 font-[700] text-[29px]  max-sm:text-[20px] max-sm:mx-5 '>Ofrecemos lo  mejor de lo mejor para ti<span className='color-cabiar'>.</span></p>

                    <div className='flex   justify-center items-center mt-14 mb-8 flex-1 flex-wrap'>
                        <div className='w-[400px] max-lg:w-[500px] max-sm:w-[95%] bg-[#2A2A2A] flex flex-col items-center px-8 py-9 rounded-[18px]  '>
                            <div className=' flex  items-center  '>
                                <i class="text-[#fff] fa-solid fa-dollar-sign self-start text-[30px] py-4 px-6 bg-[#213CC1] rounded-md"></i>
                                <div className='flex flex-col  justify-center ml-7 '>
                                    <p className='font-[600] text-[#fff] text-[20px] max-sm:text-[17px]'>Todo método de pago</p>
                                    <p className='text-[#fff]'>Te facilitamos la vida</p>
                                </div>
                            </div>
                            <div className='mx-8 my-7 border-solid border-[1px] border-[#FFFFFF17] w-full '>
                            </div>
                            <div className=''>
                                <i class="fa-brands fa-cc-visa text-[25px] text-white  "></i>
                                <i class="fa-brands fa-cc-mastercard text-[25px] text-white ml-7  "></i>
                                <i class="fa-brands fa-cc-amex text-[25px] text-white ml-7  "></i>
                                <i class="fa-brands fa-cc-paypal text-[25px] text-white ml-7  "></i>
                            </div>
                        </div>


                        <div className='w-[400px] max-lg:w-[500px] max-sm:w-[95%] bg-[#213CC1] flex flex-col items-center px-8 py-9 rounded-[18px] ml-8 max-lg:ml-0  max-lg:mt-7'>
                            <div className=' flex  items-center  '>
                                <i class="text-[#fff] fa-solid fa-shield-halved self-start text-[30px] py-4 px-5 bg-[#2A2A2A] rounded-md"></i>
                                <div className='flex flex-col  justify-center ml-7 '>
                                    <p className='font-[600] text-[#fff] text-[20px] max-sm:text-[17px]'>Seguridad Primero</p>
                                    <p className='text-[#fff]'>Nuestro Norte</p>
                                </div>
                            </div>
                            <div className='mx-8 my-7 border-solid border-[1px] border-[#FFFFFF17] w-full '>
                            </div>
                            <div className='flex items-center  '>
                                <div className='flex items-center mr-8'>
                                    <i class="fa-regular fa-credit-card text-[25px] text-white  "></i>
                                    <p className='ml-3  text-white'>Garantía</p>
                                </div>
                                <div className='flex items-center'>
                                    <i class="fa-solid fa-car  text-[25px] text-white  "></i>
                                    <p className='ml-3  text-white'>Viaje ágil</p>
                                </div>
                            </div>
                        </div>


                        <div className='w-[400px] max-lg:w-[500px] max-sm:w-[95%] bg-[#fff] shadow-md flex flex-col items-center px-8 py-9 rounded-[18px] ml-8 max-lg:ml-0 max-lg:mt-7'>
                            <div className=' flex  items-center  '>
                                <i class="text-[#fff] fa-solid fa-tablet-screen-button self-start text-[30px] py-4 px-5 bg-[#213CC1] rounded-md"></i>
                                <div className='flex flex-col  justify-center ml-7 '>
                                    <p className='font-[600] text-[#000] text-[20px] max-sm:text-[17px]'>100% Digítal</p>
                                    <p className='text-[#000]'>Disponble en</p>
                                </div>
                            </div>
                            <div className='mx-8 my-7 border-solid border-[1px] border-[#EAEAEA] w-full '>
                            </div>
                            <div className='flex items-center  '>
                                <div className='flex items-center mr-8'>
                                    <i class="fa-brands fa-apple  text-[25px] text-[#213CC1]  "></i>
                                    <p className='ml-3  text-black'>Apple</p>
                                </div>
                                <div className='flex items-center '>
                                    <i class="fa-brands fa-android text-[25px] text-[#213CC1]  "></i>
                                    <p className='ml-3  text-black'>Android</p>
                                </div>

                            </div>
                        </div>



                    </div>

                    <div className='w-full flex  bg-[#fff] justify-center items-center mt-[10rem] max-sm:mt-[4rem] mb-[7rem]   flex-wrap'>
                        <div className='relative w-auto max-lg:w-[100%] bg-[#fff] flex flex-col '>
                            <img src={riderimg} className='w-[300px] object-contain max-lg:w-[600px] max-sm:w-[95%] max-sm:mx-4  rounded-[14px] self-end' alt='' />
                            {/* <div className=' absolute shadow-xl   px-7 py-7 bottom-[-40px] left-[-150px] right-[90px]   max-lg:left-[220px]  max-sm:left-[30px]  max-sm:right-[30px]  max-lg:right-[100px]  bg-[#fff] rounded-[18px]'>
                                <p className='pl-6 border-solid italic border-[#FDB813] border-l-[3px]	text-[18px] text-[#6D6D6D] max-sm:text-[14px]'> “Lorem ipsum dolor sit amet, consecreq tetur adipiscing elit, sed do eiusmod tempor incididunt ut labore”.</p>
                                <p className='font-[700] mt-6 '>Chase Foster</p>
                            </div> */}
                        </div>
                        <div className='  flex-col items-center  justify-center flex w-[50%] max-lg:w-[100%]  max-lg:mt-[6rem] '>
                            <div className="ml-12  ">
                                <p className='text-[1.1rem] color-cabiar font-[700]'>  Acerca de Cabiar </p>
                                <p className='text-[2.8rem]   font-[700]  w-[95%]  max-sm:w-[95%] max-sm:text-[1.3rem] line-h-title '>El más confiable servicio de transporte en la región</p>
                                {/* <p className='text-[1.1rem]   text-[#6D6D6D]  max-sm:text-[0.9rem]   max-sm:w-[95%]'> Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum socis natoque penatibus. </p> */}
                            </div>

                            <div className='ml-12 mt-8 self-start flex items-center   max-sm:w-[80%]'>
                                <i class="fa-solid fa-map self-start text-[#fff] text-[25px] max-sm:text-[18px] px-6 py-4 bg-[#2A2A2A] rounded-[10px]"></i>
                                <div className='flex flex-col ml-4'>
                                    <p className='text-[#000] font-[600] text-[1.5rem]  '>Viajes a corta distancia</p>
                                    <p className='max-sm:text-[13px]'>Nuestros conductores están preparados para travesías a cualquier distancia. Solicíta tu viaje a través de la app.</p>
                                </div>
                            </div>

                            <div className='ml-12 mt-9 self-start flex items-center max-sm:w-[80%]'>
                                <i class="fa-solid fa-car-rear self-start text-[#fff] text-[25px]  max-sm:text-[18px] px-6 py-4 bg-[#213CC1] rounded-[10px]"></i>
                                <div className='flex flex-col ml-4'>
                                    <p className='text-[#000] font-[600] text-[1.5rem]  '>Al trabajo, aeropuerto, restaurante o al colegio</p>
                                    <p className='max-sm:text-[13px]'>Tu decides el destino y unos de nuestros conductores te llevará de manera segura y eficiente.</p>
                                </div>
                            </div>

                            {/* <div className='ml-12 border-solid border-[1px] border-[#eaeaea] w-[100%] self-start  my-[4rem] max-lg:w-[80%]'></div> */}
                            {/* <div className='ml-12 flex items-center khula'>
                                <div className='mr-7'>
                                    <img src={suportimg} alt='' className='w-[100px] h-[100px] rounded-[50px] object-contain' />
                                </div>
                                <div>
                                    <h3 className='text-[#6d6d6d] mb-1  max-lg:text-[1rem]'>We Are Available 24 Hours</h3>
                                    <p className='text-[#2a2a2a] text-[24px]  max-lg:text-[1rem]'>For Booking : <span className='color-cabiar font-[600]'>(+62) 8896-2220</span></p>
                                </div>
                            </div> */}
                        </div>



                    </div>


                    <ServiceCard />

                    <div className='w-full flex  bg-[#fff] justify-center  items-center mt-[1rem] max-sm:mt-[4rem] mb-[5rem] flex-wrap '>
                        <div className='relative bg-cover w-[100%]   flex-col flex items-center justify-center ' style={{ backgroundImage: `url(${app3})` }}>
                            <div className=' flex items-center justify-center flex-wrap  max-sm:pb-[1rem] max-lg:pb-[1rem] '>
                                <img className='  mt-[0px] py-[2rem] object-contain w-[800px] max-sm:w-[300px] h-[600px]   max-lg:w-[500px] ' src={app2} alt='' />
                                <div className="ml-12 w-[30%] max-lg:w-[70%] max-sm:w-[100%] ">
                                    <p className='text-[1.1rem] text-[#fff] font-[600] mb-3'>Descarga Cabiar App</p>
                                    <p className='text-[2.6rem] text-[#fff] font-[700] pr-8  max-sm:w-[95%] max-sm:text-[1.1rem]   '>Descarga Cabiar App desde tu dispositivo<span className='color-cabiar'>.</span></p>
                                    <p className='text-[0.9rem] text-[#fff] max-sm:text-[0.9rem]   max-sm:w-[95%] mt-4'>Comienza a ganar puntos mientras viajas</p>

                                    <div className='flex items-center mt-7'>
                                        <a target='blank' href='https://apps.apple.com/us/app/cabiar/id6472674617'>
                                            <img className='cursor-pointer object-fit w-[150px] max-sm:w-[100px]' src={apple} alt='' />
                                        </a>
                                        <a target='blank' href='https://play.google.com/store/apps/details?id=com.jdrm.Cabiar'>
                                            <img className='cursor-pointer object-fit w-[150px] ml-4 max-sm:w-[100px]' src={play} alt='' />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <ServiceType />
                    <FooterHead />
                </div>
            </div>
            <Modal ref={modal_ref} className="flex items-center justify-center flex-col"   >
                <div className='flex justify-start flex-col bg-white px-12 pt-7 pb-8 rounded-md'>
                    <ModalHeader onClose={closeModal} title=" " />
                    <div className='flex justify-start flex-col bg-white px-12 pt-7 pb-8 rounded-md'>
                        <iframe width="1691" height="674" src="https://www.youtube.com/embed/QAWwuC3VHG8" title="La Ventaja Cabiar" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                </div>
            </Modal>
            <Footer />
        </div>
    )
}

export default Home;