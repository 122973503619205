import React, { useState, useEffect, Component } from 'react';
import GoogleMapReact, { Position } from 'google-map-react';
// import { ws } from '../../WebSocket';
// import car from '../../img/car_3d_blanco_ok2.png';
// import us from '../../img/user.png';
import imgUs from '../../assets/img/avatarUser.jpg'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";
import io from "socket.io-client";
import Constants from '../../Constants/ConstantsApi';
import axios from 'axios';

var socketM = null;
const AnyReactComponent = ({img }) => <img alt="" src={img} id="car_move" style={{ width: 40, borderRadius: 40, height: 40, marginLeft: -20, marginTop: -20,  objectFit: "cover"  }} className="markert" />;
// const basefoto = "data:image/jpeg;base64,";
const MapShare = () => {
  const params = useParams();
  const [position, setPosition] = useState(null);
  // const perfilUrl = `https://cabiar-bucker.s3.us-east-2.amazonaws.com/${params.user}/${params.img}`;
  // console.log(perfilUrl)

  const [user, setUser] = useState(null);

  useEffect(() => { 
    // console.log("perfilUrl ",perfilUrl)
    const user = { id: params.user, name: params.name, img: null };
    //console.log(user)
    setUser(user);
    // axios.post(Constants.API_URL_LOCAL + "/bsucarUserFoto", { id: params.id }).then((d) => {
    //   if (d.status === 200) {
    //     if (d.data.datos) {
    //       setUser(d.data.datos);
    //     }
    //   }
    // })
  }, []);

  useEffect(() => {
    if (user) {
      if (socketM === null) {
        console.log(user)
        socketM = io(Constants.API_URL_LOCAL_SOCKET, { query: { type: "N", id: user.id, web: true, country: "DO" }, transports: ['websocket'] });

      }
    }
  }, [user])
  useEffect(() => {
    if (position) {

      const img_car = document.querySelector("#car_move");

    }
  }, [position, user])

  useEffect(() => {
    if (socketM) {
      socketM.on("DRIVER_SHARE", (p) => {
       // console.log(p);
        setPosition(p.coords);
        if (!user?.img) {
          setUser({ ...user, img: p.url});
        }
      });
    }


    return () => {
      if (socketM){
        socketM.off("DRIVER_SHARE"); 
      }
    }
  }, [user]);
  const defaultProps = {
    center: {
      lat: position ? position.latitude : 19.5020,
      lng: position ? position.longitude : -70.7136
    },
    zoom: position ? 18 : 9
  };

  const mover = () => {
    setPosition({ latitud: 19.5029, longitud: -70.7134 });
  }

  return (
    // Important! Always set the container height explicitly
    <div style={{ display: "flex", flexDirection: "column" }}>

      <div style={{ width: '100%', height: '70vh' }}>
        {position && user &&
          <GoogleMapReact

            bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY}}
            center={{
              lat: position ? position.latitude : 19.5020,
              lng: position ? position.longitude : -70.7136
            }}
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
          >
            <AnyReactComponent
            img={user?.img}
            lat={position.latitude}
            lng={position.longitude}
            text=""
          />
          </GoogleMapReact>}

      </div>
      {<div className="cont-infu" style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}  >
        {user && <>
        <img alt="" src={user?.img} className="img_user" style={{objectFit: "cover"}} />
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            {/* <StarIcon style={{ color: "#ffc107", fontSize: 30 }}   >star</StarIcon> */}
            {/* <span style={{ marginLeft: 7, fontWeight: "bold" }}>{user.rating}</span> */}
          </div>
          <div><b>Nombre</b>: {user.name}</div>
          {/* <div><b>Telefono</b>: {user.telformat}</div>
          {user.cedula && <div><b>cédula</b>: {user.cedula || ""}</div>}
          {user.email && <div><b>Email</b>: {user.email || ""}</div>} */}
        </>}
      </div>}

    </div>
  );

}

export default MapShare;