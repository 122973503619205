
import React from 'react'


const FooterHead = () => {

    return(
        <div className='w-full mt-[10rem] bg-[#fff] khula flex justify-center items-center' >
            <div className='w-[100%] flex flex-col justify-center items-center'>
                <div className='w-full flex flex-col px-8 py-[8rem] max-lg:py-8 khula bg-[#213CC1] justify-center  items-center mt-[1rem] max-sm:mt-[4rem]   flex-wrap'>
                    <div className='w-full flex flex-col justify-center items-center '>
                        <p className='text-[17px] text-[#fff] pb-3 '>Llame Servicio 24 Horas Disponible</p>
                        <p className='text-[2.4rem] text-[#fff] font-[600] w-[40%] max-lg:text-[1.5rem] max-lg:w-full text-center line-h-1'>Registrate ya y comienza a ganar puntos en cada viaje.</p>
                        {/* <div className='flex items-center text-[#fff] text-[1.8rem] mt-8'>
                            <i class="text-[#fff] fa-solid fa-phone self-start text-[30px] py-4 px-5 bg-[#222] rounded-md mr-8"></i>
                            <p clas>(021) 111 444 90</p>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>

    )
}

export default FooterHead;
