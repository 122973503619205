import React, { useEffect, useState } from 'react'
import logo from '../../assets/logos/logoCabiar1.jpg'
import bg from '../../assets/img/taxi-sign-at-night-e1635074452350.jpg'
import appimng from '../../assets/img/app3-ST8ZZE3-e1635131579605.png'
import riderimg from '../../assets/img/calling-taxi-e1635074462162-pf18t5veh4igp45apzdjwsc3r1x9hu53voy1wv69i0.jpg'
import taxi1 from '../../assets/img/taxi-car-on-the-city-street-e1635074489367.jpg'
import taxi2 from '../../assets/img/couple-on-a-taxi-in-manhattan-e1635074481719.jpg'
import aboutimg from '../../assets/img/trip-travel-and-vacations-concept-woman-with-yellow-suitcase-is-waiting-for-the-taxi-e1635074714722-pf18zqqqbdhrsqmo5s64pux57fhccj26ujd89jx1qq.jpg'
import aboutimg2 from '../../assets/img/female-traveler-trying-to-chatch-car-e1635074705543-pf18zia6lv66w8yyj6ihlf1zuyn1f94ltdhuy29laq.jpg'
import aboutimg3 from '../../assets/img/taxi-PNTQUQ5.png'
import fondoabout4 from '../../assets/img/1e97aa2d-5272-41ab-8f49-c226fd4b7013.jpg'
import leftfondo from '../../assets/img/AdobeStock_524087524.jpeg'
import rigthfondo from '../../assets/img/AdobeStock_558107599.jpeg'
import app2 from '../../assets/img/app2-ST8ZZE3-e1635074601447.png'
import app3 from '../../assets/img/fodoazul.jpeg'
import app4 from '../../assets/img/4415922.jpg'
import teamimg1 from '../../assets/img/happy-man-smiling-with-hands-in-pocket-against-a-w-XA7YR8F.jpg'
import teamimg2 from '../../assets/img/portrait-of-happy-handsome-bearded-turkish-man-smi-L5DJ897.jpg'
import aboutimg5 from '../../assets/img/Banner_Ridesharer.jpg'
import servicesimg from '../../assets/img/young-asian-pretty-woman-waving-her-hand-on-the-road-pf0red5t4afdcakxrtm8mmym5nz8u7v7wvid5gy9k0.jpg'
import apple from '../../assets/img/app-store1.png'
import play from '../../assets/img/google-play-badge-e1630982322931.png'
import suportimg from '../../assets/img/indian-operator-assistant-using-a-microphone-headset-while-conversing-with-a-customer-e1635131565916-pf2e84hd1ng479qhvl4hsikf3ewxko9o7u4ezlvimo.jpg'
import { Link } from 'react-router-dom'
import Header from '../../common/Header'
import Footer from '../../common/Footer'
import '../../css/pulse.css'

import ProgressBar from './ProgressBar'
import ServiceCard from './ServiceCard'
import ServiceType from './ServiceType'
let interval1 = null;
const Services = () => {
    const [progressValue1, setProgressValue1] = useState(80);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    // useEffect(() => {
    //     interval1 = setInterval(() => {
    //         setProgressValue1((prevValue) => (  prevValue + 1  ));
    //     }, 80);

    //     return () => clearInterval(interval1);
    // }, []);


    // if(interval1 && progressValue1 >= 80){
    //     clearInterval(interval1)
    // }
    return (

        <div className="w-full bg-white ">

            <Header pg={3} />
            <div className="w-full h-[500px] bg-cover	  " style={{ background: `url(${bg})` }}>
                <div className="w-full h-[100%] Khula bg-[#000000a8] " >
                    <div className='flex-1  flex flex-col justify-center items-center  h-[100%] '>
                        <p className='text-[#fff] text-[60px] font-[700] max-sm:text-[40px]   '>
                            Viaja<span className='color-cabiar'>.</span>
                        </p>
                        <p className='text-white'><span >Home</span> <i class="mx-3 color-cabiar text-[13px] fa-solid fa-greater-than"></i> <span>Viaja</span></p>

                    </div>
                </div>
            </div>
          
            <ServiceType />
            <ServiceCard />


            <div className='hidden w-full flex  bg-[#fff]   items-center mt-[5rem] max-sm:mt-[4rem] mb-[8rem] flex-wrap justify-center px-[8rem]'>
                <div className='flex-1'>
                    <img src={servicesimg} alt='' className='w-[600px] rounded-[20px]' />
                </div>
                <div className=" flex-1  ">
                    <p className='text-[1.1rem] color-cabiar font-[700]'>  Clients Inquiries </p>
                    <p className='text-[2.8rem]   font-[700]  w-[95%]  max-sm:w-[95%] max-sm:text-[1.3rem]  '> Related Questions. </p>
                    <p className='text-[1.1rem]   text-[#6D6D6D]  max-sm:text-[0.9rem]   max-sm:w-[95%]'> Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum socis natoque penatibus. </p>

                    <div className='my-[2rem]'>
                        <div className='mb-[1rem] flex items-center shadow-md rounded-lg px-7 py-6 cursor-pointer'>
                            <i class="fa-solid fa-chevron-right"></i>
                            <div className='mx-4 font-[600] text-[18px]'>Are there offices in other areas?</div>
                        </div>
                        <div className='mb-[1rem] flex items-center shadow-md rounded-lg px-7 py-6 cursor-pointer'>
                            <i class="fa-solid fa-chevron-right"></i>
                            <div className='mx-4 font-[600] text-[18px]'>Are there any alternatives regarding payment?</div>
                        </div>
                        <div className='mb-[1rem] flex items-center shadow-md rounded-lg px-7 py-6 cursor-pointer'>
                            <i class="fa-solid fa-chevron-right"></i>
                            <div className='mx-4 font-[600] text-[18px]'>Is there an offer for each transaction?</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='w-full mt-[10rem] bg-[#fff] khula flex justify-center items-center'>
                <div className='w-[100%] flex flex-col justify-center items-center'>
                    <div className='w-full flex flex-col px-8 py-[8rem] max-lg:py-8 khula bg-[#213CC1] justify-center  items-center mt-[1rem] max-sm:mt-[4rem]   flex-wrap'>
                        <div className='w-full flex flex-col justify-center items-center '>
                            <p className='text-[17px] text-[#fff] pb-3 '>Call 24 Hour Service Available</p>
                            <p className='text-[2.4rem] text-[#fff] font-[600] w-[40%] max-lg:text-[1.5rem] max-lg:w-full text-center'>Call Now & Book Your Taxi For Your Next Ride.</p>
                            <div className='flex items-center text-[#fff] text-[1.8rem] mt-8'>
                                <i class="text-[#fff] fa-solid fa-phone self-start text-[30px] py-4 px-5 bg-[#222] rounded-md mr-8"></i>
                                <p clas>(021) 111 444 90</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Services;