import CryptoJS from 'crypto-js'

//  const encrypter = (object, key) => {
//   let hash = CryptoJS.AES.encrypt(JSON.stringify(object), key).toString()
//   return hash
// }

//  const decrypter = (hashedValue, key) => {
//   let auxValue = CryptoJS.AES.decrypt(hashedValue, key)
 
//   let value = JSON.parse(CryptoJS.enc.Utf8.stringify(auxValue))
//   return value
// }
 
const encrypter = (object, key) => {
  let hash = CryptoJS.AES.encrypt(JSON.stringify(object), key).toString()
  return hash
}

const decrypter = (hashedValue, key) => {
  let auxValue = CryptoJS.AES.decrypt(hashedValue, key)

  let value = JSON.parse(CryptoJS.enc.Utf8.stringify(auxValue))
  return value
}
export default {
    encrypter,
    decrypter
}