import React, { useState, useRef, memo, useEffect, useLayoutEffect } from "react";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
 
 
 

const Terminos = (props) => {
    window.scrollTo(0,0); 
    return (
        <>
 
            <div style={{ flex: 1, backgroundColor: "#fff", }} >
            <Header  />
                <div style={{ flex: 1, padding: 20, marginTop: 77 }}>
                    <div style={{ flex: 1, marginBottom: 50 }}>
                        <p className="titleP">Términos y condiciones</p>
                        <p  className="desc">Al acceder nuestra aplicación Cabiar y sitio web, usted acepta estar vinculado por estos términos de servicio,
                            todas las leyes y regulaciones aplicables, y acepta que usted es responsable del cumplimiento de las leyes locales aplicables.
                            Si no está de acuerdo con alguno de estos términos, se le prohíbe utilizar o acceder a esta app. Los materiales contenidos en este sitio web están
                            protegidos por las leyes de derechos de autor y marcas aplicables.</p>
                        <p className="titleD">Licencia de Uso</p>
                        <p  className="desc">Sujeto al cumplimiento de estas Condiciones, Cabiar le otorga una licencia limitada, no exclusiva,
                            no sublicencia, revocable, no transferible para: el acceso y uso de las Aplicaciones en su dispositivo personal solo en relación con su uso de los Servicios; y el acceso y uso de cualquier contenido, información y material relacionado que pueda ponerse a disposición a través de los Servicios, en cada caso solo para su uso personal, no comercial. Cabiar y sus licenciantes se reservan cualquier derecho que no haya sido expresamente otorgado por el presente.
                            Esta licencia se terminará automáticamente si usted viola cualquiera de estas restricciones y puede ser terminada por Cabiar en cualquier momento.
                        </p>
                        <p className="titleD">Responsabilidad </p>
                        <p  className="desc">El usuario es el único responsable de la seguridad de
                            su usuario y de las pérdidas que ocasione su uso no autorizado. Los usuarios que
                            prestan servicios utilizando el servicio no son empleados. No somos responsables de
                            ninguna propiedad del usuario que utiliza nuestro servicio. No somos responsables en
                            ningún caso de acciones de los usuarios y terceros de las circunstancias de fuerza
                            mayor que causaron daño moral y (o) material, así como daño a la vida y la salud.
                            En ningún caso nosotros participaremos en disputas entre usuarios, entre el usuario y
                            terceros, entre usuarios y autoridades públicas. No somos responsable de infracciones
                            daños o perdidas en relación con actividades comerciales, ganancias no devengadas,
                            reputación comercial, bienes dañados o perdidos, valor, propiedad, datos o
                            documentación incurrido por esta u otra persona como resultado vinculado al uso
                            del servicio aun cuando tengamos conocimiento de la posibilidad de tal daño. </p>

                        <p className="titleD">Limitaciones </p>
                        <p  className="desc">En ningún caso Cabiar o sus proveedores serán responsables
                            de ningún daño (incluyendo, sin limitación, daños por pérdida de datos o beneficio,
                            o debido a la interrupción del negocio) que surjan del uso o la incapacidad de
                            utilizar los servicios de Cabiar, incluso si Cabiar o un representante autorizado de
                            Cabiar ha sido notificado verbalmente o por escrito de la posibilidad de tal daño.
                            Debido a que algunas jurisdicciones no permiten limitaciones a las garantías implícitas,
                            o limitaciones de responsabilidad por daños consecuentes o incidentales,
                            es posible que estas limitaciones no se apliquen a usted.</p>

                        <p className="titleD">Restricciones </p>
                        <p  className="desc">Usted no podrá: retirar cualquier nota de derechos de autor,
                            marca registrada u otra nota de propiedad de cualquier parte de los Servicios;
                            reproducir, modificar, preparar obras derivadas sobre los Servicios, distribuir,
                            licenciar, arrendar, revender, transferir, exhibir públicamente, presentar
                            públicamente, transmitir, retransmitir o explotar de otra forma los Servicios,
                            excepto como se permita expresamente por Cabiar;  descompilar, realizar ingeniería
                            inversa o desmontar los Servicios, excepto como se permita por la ley aplicable;
                            enlazar, reflejar o enmarcar cualquier parte de los Servicios; causar o lanzar
                            cualquier programa o script con el objeto de extraer, indexar, analizar o de otro modo
                            realizar prospección de datos de cualquier parte de los Servicios o sobrecargar o bloquear
                            indebidamente la operación y/o funcionalidad de cualquier aspecto de los Servicios; o intentar
                            obtener un acceso no autorizado o dañar cualquier aspecto de los Servicios o sus sistemas o redes relacionados.
                            Los Servicios y todos los derechos relativos a estos son y permanecerán de la propiedad de Cabiar
                            o de sus licenciantes. Ninguna de estas Condiciones ni su uso de los Servicios le transfieren u
                            otorgan ningún derecho: sobre o en relación con los Servicios, excepto en cuanto a la licencia
                            limitada otorgada anteriormente; o bien a utilizar o mencionar en cualquier modo a los nombres
                            de empresa, logotipos, nombres de producto y servicio, marcas comerciales o marcas de servicio
                            de Cabiar o de sus licenciantes.
                        </p>

                        <p className="titleD">Prohibiciones</p>
                        <p  className="desc">
                            Está prohibido usar este servicio para el propósito de: <br /><br />
                            <div style={{marginLeft: 15}}>
                            <span><b>1.</b> Atracción de Usuarios a recursos, sitios de terceros o registro de Usuarios en dichos recursos, sitios.</span>
                            <br /><span><b>2.</b> Servicios de carácter erótico.</span>
                            <br /><span><b>3.</b> Cualquier otro servicio prohibido por las leyes de la Republica Dominicana.</span>
                            <br /><span><b>4.</b> Servicios de almacenamiento, transporte, fabricación, procesamiento de estupefacientes, precursores, sustancias psicotrópicas o sus análogos, así como la adquisición, almacenamiento, transporte ilegal de plantas que contengan estupefacientes o sustancias psicotrópicas, o sus partes que contengan estupefacientes o sustancias psicotrópicas.</span>
                            <br /><span><b>5.</b> Armas y municiones (civiles, de servicio, armas de fuego).</span>
                            <br /><span><b>6.</b> Otorgar y recibir préstamos, reponer cuentas en sistemas de pago, realizar transferencias de dinero, aceptar pagos a favor de sistemas de pago.</span>
                            <br /><span><b>7.</b> Servicios para la organización de la prostitución, participación en la prostitución.</span>
                            <br /><span><b>8.</b> Servicios para la colocación de información, materiales que desacrediten el honor y la dignidad de una persona, violación del secreto de la correspondencia, vida personal.</span>
                            <br /><span><b>9.</b> Servicios para la compra / venta / entrega de medicamentos.</span>
                            <br /><span><b>10.</b> Comercio, incluidas las principales partes de las armas de fuego.</span>
                            <br /><span><b>11.</b> Contenido para adultos, cualquier tipo de pornografía, chats de videos sexuales, sitios con una indicación de la publicación de fotos explícitas.</span>
                            <br /><span><b>12.</b> Desarrollo, producción, prueba, almacenamiento, reparación y eliminación.</span>
                            <br /><span><b>13.</b> Servicios para la fabricación / conversión / reparación de explosivos o artefactos explosivos.</span>
                           </div>
                        </p>

                        <p className="titleD">Modificaciones</p>
                        <p  className="desc">Cabiar puede revisar estos términos de servicio para su app y sitio web en
                            cualquier momento sin previo aviso. Al utilizar esta app y sitio web,
                            usted acepta estar vinculado por la versión actual de estos términos de servicio. </p>
                  
                            <p className="titleD">Ley Aplicable</p>
                            <p  className="desc">Estos términos y condiciones se rigen e interpretan de 
                            acuerdo con las leyes globales y usted se somete irrevocablemente a la 
                            jurisdicción exclusiva de los tribunales en ese estado o ubicación.</p>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
};

export default Terminos;
 