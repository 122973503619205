import React from 'react'
import footerbg from '../assets/img/dubai-aerial-view-e1635074776583.jpg';
import logo from '../assets/logos/logoCabiar1.jpg'
import { Link } from 'react-router-dom'
const Footer = () => {

    return (

        <div className="w-full flex flex-col  items-center justify-center " style={{ backgroundImage: `url(${footerbg})` }}>
            <div className="w-full bg-[#2d2d2df0] px-[2rem] py-[4rem]  items-center justify-center">
                <div className=" flex-wrap  flex flex-row items-center justify-around mt-[4rem] ">
                    {/* <div className='mt-[1rem] text-center '   >
                        <div className='text-[#fff]    text-[1.9rem] font-[600] mb-3'>Address</div>
                        <div className='text-[#fff]'>Jl. Merdeka Raya No.73B, Kuta,</div>
                        <div className='text-[#fff]'>Kabupaten Badung, Bali</div>
                    </div> */}
                    <div className='flex-1 mt-[1rem] text-center '   >
                        <div className='text-[#fff] text-[1.9rem] font-[600] mb-3'>Contacto</div>
                        <div className='text-[#fff]'>info@cabiar.com</div>
                        {/* <div className='text-[#fff]'>(+62) 8896-2220</div> */}
                    </div>
                    <div className='flex-1 mt-[1rem] text-center '   >
                        <div className='text-[#fff] text-[1.9rem] font-[600] mb-3'>Asistencia</div>
                        <div className='text-[#fff]'>24/7</div>
                        {/* <div className='text-[#fff]'>(+62) 8896-2220</div> */}
                    </div>
                    <div className='flex-1 mt-[1rem] text-center flex flex-col justify-center items-center '   >
                        <div className='text-[#fff]  text-[1.9rem] font-[600] mb-3'>Boletin informativo</div>
                        <div className='text-[#fff]'>
                            <div className='flex flex-row items-center mt-[1rem] flex-1' >
                                {/* <label className='label-inputs'>Sub Titulo</label> */}
                                <input className='inputs w-[300px]  p-[4px] h-[50px]' placeholder='Email' />
                                <div><i class="fa-regular fa-paper-plane ml-3 bg-[#213CC1] px-[1rem] py-[1rem] rounded-md cursor-pointer "></i></div>
                            </div>
                        </div>
                        {/* <div className='text-[#fff]'>Sun : 09:00 - 18:00</div> */}
                    </div>

                </div>
                <div className='flex flex-row items-center flex-wrap justify-evenly mt-[8rem]  border-t-[1px] pt-[3rem]   border-[#FFFFFF17]'>
                    <div className='text-[#fff] flex-1 clear-both  '>© 2023 Cabiar Tehcnologies, LLC</div>
                    <div className='flex-1 ml-[15rem] max-sm:ml-[0px] '><img className='w-[100px] h-[100px] object-contain    cursor-pointer' alt='' src={logo} /></div>
                    <div className='  flex items-center'>
                        <Link to="/privacy-policies" className='text-[#fff] mr-[25px] cursor-pointer'>Política de privacidad</Link>
                        <Link  to="/terms-and-conditions"  className='text-[#fff] cursor-pointer'>Términos y condiciones</Link>
                    </div>
                </div>


            </div>
        </div>
    )
}

export default Footer;