//  const API_URL_LOCAL = "http://10.0.0.77:8070";
 //const API_URL_LOCAL = "http://192.168.0.177:8070";
//  const API_URL_LOCAL = "http://nueva-prueba-lb-911971035.us-east-1.elb.amazonaws.com";
//  const API_URL_LOCAL_SOCKET = "ws://nueva-prueba-lb-911971035.us-east-1.elb.amazonaws.com";
//  const API_URL_LOCAL = "http://52.71.91.229:8070";
//  const API_URL_LOCAL_SOCKET = "ws://52.71.91.229:8070";
//  const API_URL_LOCAL = "https://www.halcon.com.do";
//  const API_URL_LOCAL_SOCKET  = "https://www.halcon.com.do";

//  const API_URL_LOCAL = "http://192.168.100.5:8050";
//  const API_URL_LOCAL_SOCKET  = "http://localhost:8050";
//  const API_URL_LOCAL = "https://cabiar.com";
//  const API_URL_LOCAL_SOCKET  = "https://cabiar.com";
//  const API_URL_VIVO_T = "https://cabiar.com";

//  const API_URL_LOCAL = "https://cabiar.com";
//  const API_URL_LOCAL_SOCKET  = "https://cabiar.com";
//  const API_URL_VIVO_T = "https://cabiar.com";

 const API_BANDERA_PAIS = "https://flagcdn.com/32x24/";
 
const data = {
    API_URL_LOCAL: process.env.REACT_APP_SOCKET_URL,
    API_URLL_VIVO_T: process.env.REACT_APP_SOCKET_URL,
    API_URL_LOCAL_SOCKET: process.env.REACT_APP_SOCKET_URL,
    API_BANDERA_PAIS: API_BANDERA_PAIS 
    
}
export default data