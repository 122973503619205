
import React, {useEffect, useState} from 'react'
import { motion } from 'framer-motion';

const ProgressBar = ({ value, maxValue, initial }) => {
    const progress = Math.min((value / maxValue) * 100 );

    return (
      <div className="progress-bar-container">
        <motion.div
          className="progress-bar"
          initial={{ width: `{initial}%` }}
          animate={{ width: `${progress}%` }}
          transition={{ duration: 1, ease: 'easeInOut' }}
        ></motion.div>
        <motion.div
          className="progress-text"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, ease: 'easeInOut' }}
        >
          {Math.round(progress)}%
        </motion.div>
      </div>
    )
  };
  
  export default ProgressBar;


