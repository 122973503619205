import React, { useEffect, useState } from 'react'
import logo from '../../assets/logos/logoCabiar1.jpg'
import bg from '../../assets/img/taxi-sign-at-night-e1635074452350.jpg'
import appimng from '../../assets/img/app3-ST8ZZE3-e1635131579605.png'
import riderimg from '../../assets/img/calling-taxi-e1635074462162-pf18t5veh4igp45apzdjwsc3r1x9hu53voy1wv69i0.jpg'
import taxi1 from '../../assets/img/taxi-car-on-the-city-street-e1635074489367.jpg'
import taxi2 from '../../assets/img/couple-on-a-taxi-in-manhattan-e1635074481719.jpg'
import aboutimg from '../../assets/img/trip-travel-and-vacations-concept-woman-with-yellow-suitcase-is-waiting-for-the-taxi-e1635074714722-pf18zqqqbdhrsqmo5s64pux57fhccj26ujd89jx1qq.jpg'
import aboutimg2 from '../../assets/img/female-traveler-trying-to-chatch-car-e1635074705543-pf18zia6lv66w8yyj6ihlf1zuyn1f94ltdhuy29laq.jpg'
import aboutimg00 from '../../assets/img/taxi-PNTQUQ5.png'
import aboutimg3 from '../../assets/img/AdobeStock_497019700.png'
import About001 from '../../assets/img/About001.jpeg'
import About002 from '../../assets/img/About002.jpeg'
import aboutimg6 from '../../assets/img/4085493.jpg'
import fondoabout4 from '../../assets/img/1e97aa2d-5272-41ab-8f49-c226fd4b7013.jpg'
import leftfondo from '../../assets/img/AdobeStock_524087524.jpeg'
import rigthfondo from '../../assets/img/AdobeStock_558107599.jpeg'
import aboutimg7 from '../../assets/img/193e5846-01aa-4379-b0d2-ac5d98098f5c.jpg'
import app2 from '../../assets/img/app2-ST8ZZE3-e1635074601447.png'
import app3 from '../../assets/img/fodoazul.jpeg'
import app4 from '../../assets/img/4415922.jpg'
import teamimg1 from '../../assets/img/happy-man-smiling-with-hands-in-pocket-against-a-w-XA7YR8F.jpg'
import teamimg2 from '../../assets/img/portrait-of-happy-handsome-bearded-turkish-man-smi-L5DJ897.jpg'
import aboutimg5 from '../../assets/img/Banner_Ridesharer.jpg'
import aboutimg15 from '../../assets/img/AdobeStock_469999830.jpeg'
import apple from '../../assets/img/app-store1.png'
import play from '../../assets/img/google-play-badge-e1630982322931.png'
import suportimg from '../../assets/img/indian-operator-assistant-using-a-microphone-headset-while-conversing-with-a-customer-e1635131565916-pf2e84hd1ng479qhvl4hsikf3ewxko9o7u4ezlvimo.jpg'
import iconRegistate from '../../assets/img/iconRegistate.png'
import ComparteEstaopss from '../../assets/img/compartir.png'
import iconGana from '../../assets/img/intercambiar.png'
import iconPaga from '../../assets/img/tarjeta.png'
import aboubg from '../../assets/img/aboubg.jpeg'
import Team001 from '../../assets/img/Team001.png'
import Team002 from '../../assets/img/Team002.png'
import Team003 from '../../assets/img/Team003.png'
import Team004 from '../../assets/img/Team004.png'
import RegistrateIcon2 from '../../assets/img/editar.png'
import FondoCabiar from '../../assets/img/FondoCabiar.jpg'
import { Link } from 'react-router-dom'
import Header from '../../common/Header'
import Footer from '../../common/Footer'
import '../../css/pulse.css'

import ProgressBar from './ProgressBar'
import FooterHead from '../../common/FooterHead'
import FotoEquipo from '../../common/FotoEquipo'
let interval1 = null;
const About = () => {
    const [progressValue1, setProgressValue1] = useState(80);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    // useEffect(() => {
    //     interval1 = setInterval(() => {
    //         setProgressValue1((prevValue) => (  prevValue + 1  ));
    //     }, 80);

    //     return () => clearInterval(interval1);
    // }, []);


    // if(interval1 && progressValue1 >= 80){
    //     clearInterval(interval1)
    // }
    return (

        <div className="w-full bg-white ">

            <Header pg={2} />
            <div className="w-full h-[500px] bg-cover bg-no-repeat	bgaout  " style={{ backgroundImage: `url(${aboutimg15})` }}>
                <div className="w-full h-[100%] Khula bg-[#000000a8] " >
                    <div className='flex-1  flex flex-col justify-center items-center  h-[100%] '>
                        <p className='text-[#fff] text-[60px] font-[700] max-sm:text-[40px]   '>
                            About<span className='color-cabiar'>.</span>
                        </p>
                        <p className='text-white'><span >Home</span> <i class="mx-3 color-cabiar text-[13px] fa-solid fa-greater-than"></i> <span>About</span></p>

                    </div>
                </div>
            </div>

            <div className='  bg-[#fff] khula flex flex-col justify-center items-center mt-20 max-sm:mt-4 '>
                <div className='flex  justify-center items-center py-8 flex-wrap '>
                    <div className=" w-[50%]  max-lg:w-[100%]   flex flex-col  item-center  justify-center   ">
                        <div className=" w-full  flex flex-col item-center justify-center px-[10rem] max-lg:px-[4rem]  max-sm:px-[1rem]  ">
                            <p className='text-[1.1rem] color-cabiar font-[700]'>Nosotros Cabiar </p>
                            <p className='text-[2.8rem]   font-[700]    max-sm:text-[1.3rem]  '>Tu propia red de viajes<span className='color-cabiar'>.</span></p>
                            <p className='text-[1.1rem]   text-[#6D6D6D]  max-sm:text-[0.9rem]   max-sm:w-[95%]'>Solo Cabiar te ofrece la oportunidad de crear tu propia red de viajes, compartiendo esta oportunidad de disfrutar de los siguientes beneficios:</p>

                            <div className='flex   mt-8  text-[#6D6D6D]'>
                                <div className="flex-1  ">
                                    <div className='flex  items-center text-left  mb-3'>
                                        <i class="fa-solid fa-check color-cabiar"></i>
                                        <p className='ml-4'>Gana puntos mientras viajas</p>
                                    </div>
                                    <div className='flex  items-center mb-3'>
                                        <i class="fa-solid fa-check color-cabiar"></i>
                                        <p className='ml-4'>Servicio los 24/7 </p>
                                    </div>
                                    <div className='flex  items-center mb-3'>
                                        <i class="fa-solid fa-check color-cabiar"></i>
                                        <p className='ml-4'>Viajes a cualquier parte</p>
                                    </div>
                                    <div className='flex  items-center mb-3'>
                                        <i class="fa-solid fa-check color-cabiar"></i>
                                        <p className='ml-4'>Todos los metodos de pago</p>
                                    </div>
                                </div>
                                <div className="flex-1 ml-12 max-lg:ml-[1rem] ">
                                    <div className='flex  items-center text-left  mb-3'>
                                        <i class="fa-solid fa-check color-cabiar"></i>
                                        <p className='ml-4'>Atención al pasajero</p>
                                    </div>
                                    <div className='flex  items-center mb-3'>
                                        <i class="fa-solid fa-check color-cabiar"></i>
                                        <p className='ml-4'>Conductores depurados</p>
                                    </div>
                                    <div className='flex  items-center mb-3'>
                                        <i class="fa-solid fa-check color-cabiar"></i>
                                        <p className='ml-4'>Seguridad</p>
                                    </div>

                                    <div className='flex  mb-3'>
                                        <i class="fa-solid fa-check color-cabiar"></i>
                                        <p className='ml-4'>Ganas puntos en los viajes de tus invitados</p>
                                    </div>
                                </div>
                            </div>
                            {/* <div className='  flex items-center khula mt-8'>
                                <div className='mr-7'>
                                    <img src={suportimg} alt='' className='w-[80px] h-[80px] rounded-[50px] object-contain' />
                                </div>
                                <div>
                                    <h3 className='text-[#6d6d6d] mb-1  max-lg:text-[1rem]'>We Are Available 24 Hours</h3>
                                    <p className='text-[#2a2a2a] text-[24px]  max-lg:text-[1rem]'>For Booking : <span className='color-cabiar font-[600]'>(+62) 8896-2220</span></p>
                                </div>
                            </div> */}
                        </div>
                    </div>

                    <div className=' w-[50%]   flex  max-lg:w-[100%]   flex-col jsutify-center items-center '>
                        <div className='relative  flex  jsutify-center items-center '>
                            {console.log(`url(${FondoCabiar})`)}

                            <img src={About001} alt='' className='   z-10 mr-[-5rem] mt-[8rem] w-[344px]  h-[427px] object-cover max-sm:w-[150px]  max-lg:w-[250px] rounded-[7px] p-7 bg-white ' />
                            <img src={About002} alt='' className=' w-[340px] rounded-[7px]  w-[350px]  h-[405px] object-cover max-lg:w-[250px] max-sm:w-[150px]    ' />
                        </div>
                    </div>

                </div>

            </div>

            <div className='flex   justify-center items-center mt-14 mb-8 flex-1 flex-wrap'>
                <div className='w-[400px] max-lg:w-[500px] max-sm:w-[95%] bg-[#2A2A2A] flex flex-col items-center px-8 py-9 rounded-[18px]  '>
                    <div className=' flex  items-center  '>
                        <i class="text-[#fff] fa-solid fa-dollar-sign self-start text-[30px] py-4 px-6 bg-[#213CC1] rounded-md"></i>
                        <div className='flex flex-col  justify-center ml-7 '>
                            <p className='font-[600] text-[#fff] text-[20px] max-sm:text-[17px]'>Todo método de pago</p>
                            <p className='text-[#fff]'>Te facilitamos la vida</p>
                        </div>
                    </div>
                    <div className='mx-8 my-7 border-solid border-[1px] border-[#FFFFFF17] w-full '>
                    </div>
                    <div className=''>
                        <i class="fa-brands fa-cc-visa text-[25px] text-white  "></i>
                        <i class="fa-brands fa-cc-mastercard text-[25px] text-white ml-7  "></i>
                        <i class="fa-brands fa-cc-amex text-[25px] text-white ml-7  "></i>
                        <i class="fa-brands fa-cc-paypal text-[25px] text-white ml-7  "></i>
                    </div>
                </div>


                <div className='w-[400px] max-lg:w-[500px] max-sm:w-[95%] bg-[#213CC1] flex flex-col items-center px-8 py-9 rounded-[18px] ml-8 max-lg:ml-0  max-lg:mt-7'>
                    <div className=' flex  items-center  '>
                        <i class="text-[#fff] fa-solid fa-shield-halved self-start text-[30px] py-4 px-5 bg-[#2A2A2A] rounded-md"></i>
                        <div className='flex flex-col  justify-center ml-7 '>
                            <p className='font-[600] text-[#fff] text-[20px] max-sm:text-[17px]'>Seguridad Primero</p>
                            <p className='text-[#fff]'>Nuestro Norte</p>
                        </div>
                    </div>
                    <div className='mx-8 my-7 border-solid border-[1px] border-[#FFFFFF17] w-full '>
                    </div>
                    <div className='flex items-center  '>
                        <div className='flex items-center mr-8'>
                            <i class="fa-regular fa-credit-card text-[25px] text-white  "></i>
                            <p className='ml-3  text-white'>Garantía</p>
                        </div>
                        <div className='flex items-center'>
                            <i class="fa-solid fa-car  text-[25px] text-white  "></i>
                            <p className='ml-3  text-white'>Viaje áil</p>
                        </div>
                    </div>
                </div>


                <div className='w-[400px] max-lg:w-[500px] max-sm:w-[95%] bg-[#fff] shadow-md flex flex-col items-center px-8 py-9 rounded-[18px] ml-8 max-lg:ml-0 max-lg:mt-7'>
                    <div className=' flex  items-center  '>
                        <i class="text-[#fff] fa-solid fa-tablet-screen-button self-start text-[30px] py-4 px-5 bg-[#213CC1] rounded-md"></i>
                        <div className='flex flex-col  justify-center ml-7 '>
                            <p className='font-[600] text-[#000] text-[20px] max-sm:text-[17px]'>100% Digítal</p>
                            <p className='text-[#000]'>Disponble en</p>
                        </div>
                    </div>
                    <div className='mx-8 my-7 border-solid border-[1px] border-[#EAEAEA] w-full '>
                    </div>
                    <div className='flex items-center  '>
                        <div className='flex items-center mr-8'>
                            <i class="fa-brands fa-apple  text-[25px] text-[#213CC1]  "></i>
                            <p className='ml-3  text-black'>Apple</p>
                        </div>
                        <div className='flex items-center '>
                            <i class="fa-brands fa-android text-[25px] text-[#213CC1]  "></i>
                            <p className='ml-3  text-black'>Android</p>
                        </div>

                    </div>
                </div>



            </div>

            <div className='w-full flex mb-[15rem]   justify-center  items-center mt-[8rem] max-sm:mt-[4rem] mb-[5rem] flex-wrap '>
                <div className='relative bg-cover w-[100%]   flex-col flex items-center justify-center ' style={{ backgroundImage: `url(${fondoabout4})` }}>
                    <div className='text-center  w-[100%] mx-[3rem] bg-[#000000a8] py-12 flex flex-col items-center justify-center flex-wrap   max-sm:pb-[1rem] max-lg:pb-[1rem] '>

                        <div className="  w-[100%] mt-[3rem]  ">
                            <div className='flex flex-col  justify-center  items-center '>
                                {/* <p className='color-cabiar text-[18px] font-[600]'>¡Paga tus viajes con tu puntos, siempre!</p> */}
                                <p className=' text-[50px] font-[700] text-white max-lg:text-[30px]'>¡Paga tus viajes con tu puntos, siempre!<span className='color-cabiar'>.</span></p>
                                <p className='w-[70%] text-[1rem] text-white text-center text-[#6d6d6d] max-sm:text-[0.9rem]   max-sm:w-[95%]'>Regístrate en Cabiar y comienza a ganar puntos mientras viajas y mientras viajan las personas registradas a través de tu invitación. Mientras más grande sea tu red de viajeros, más puntos ganas.</p>
                            </div>
                        </div>
                        <img className='mt-[1rem]  rounded-[15px]  mb-[-10rem] object-fit w-[500px] max-sm:w-[300px]   max-lg:w-[500px] ' src={aboutimg3} alt='' />
                    </div>
                </div>
            </div>

            <div className='w-full flex mb-[15rem]   justify-center  items-center mt-[8rem] max-sm:mt-[4rem] mb-[5rem] flex-wrap '>
                <div className='relative bg-cover w-[100%]   flex-col flex items-center justify-center '  >
                    <div className='w-[100%] bg-[#fff]  py-12 flex flex-col items-center justify-center flex-wrap   max-sm:pb-[1rem] max-lg:pb-[1rem] '>

                        <div className="  w-[100%] mt-[3rem]  ">
                            <div className='flex flex-col  justify-center  items-center '>
                                <p className='color-cabiar text-[18px] font-[600]'>Cómo funciona</p>
                                <p className=' text-[50px] font-[700] text-[#000]'>Pasos simples para viajar con puntos<span className='color-cabiar'>.</span></p>
                                <p className='w-[50%] text-[1rem] text-[#000] text-center text-[#6d6d6d] max-sm:text-[0.9rem]   max-sm:w-[95%]'>Viajar con puntos es simple. Sigue estos pasos y comienza a ganar ya.</p>
                            </div>
                        </div>
                        <div className='mt-[5rem]   relative khula '>
                            <div className='z-10 flex items-center justify-center w-[100%] flex-wrap'>
                                <div className='mt-[1rem]   z-10 flex flex-col    justify-center items-center w-[350px] text-center'>
                                    <img alt="" src={RegistrateIcon2} className="w-[90px] shadow-md text-[#fff] fa-solid fa-car-rear   text-[30px] py-6 px-6 bg-[#213CC1] rounded-md" />
                                    <p className='text-[13px] font-[600] my-2'>Regístrate</p>
                                    <p className='h-[200px] text-[#6d6d6d] text-[10px] font-[400]'>Descargar tu app en Google Play o App Store.</p>
                                </div>
                                <div className='mt-[1rem] z-10 flex flex-col   justify-center items-center w-[350px] text-center'>
                                    <img alt="" src={ComparteEstaopss} className="w-[90px] shadow-md text-[#fff] fa-solid fa-car-rear   text-[30px] py-6 px-6 bg-[#213CC1] rounded-md" />
                                    <p className='text-[13px] font-[600] my-2'>Comparte esta oportuidad</p>
                                    <p className='h-[200px] text-[#6d6d6d] text-[10px] font-[400]'>Invita a otros a registrarse en Cabiar usando tu código único de invitación.</p>
                                </div>
                                <div className='mt-[1rem] z-10 flex flex-col   justify-center items-center w-[350px] text-center'>
                                    <img alt="" src={iconGana} className="w-[90px] shadow-md text-[#fff] fa-solid fa-car-rear   text-[30px] py-6 px-6 bg-[#213CC1] rounded-md" />
                                    <p className='text-[13px] font-[600] my-2'>Ganas puntos en tus viajes y los de tus invitados</p>
                                    <p className='h-[200px] text-[#6d6d6d] text-[10px] font-[400]'>Ganarás puntos en tus viajes y en los viajes que tomen las personas registradas en Cabiar a través de tu invitación.</p>
                                </div>
                                <div className='mt-[1rem] z-10 flex flex-col justify-center items-center w-[350px] text-center'>
                                    <img alt="" src={iconPaga} className="shadow-md w-[90px] text-[#fff] fa-solid fa-car-rear   text-[30px] py-6 px-6 bg-[#213CC1] rounded-md" />
                                    <p className='text-[13px] font-[600] my-2'>Pagas tus viajes con tus puntos</p>
                                    <p className='h-[200px] text-[#6d6d6d] text-[10px] font-[400]'>Podras canjear tus puntos en Cabiar Wallet para pagar tus viajes.</p>
                                </div>
                            </div>
                            <div className='z-[3] max-xl:hidden top-[60px] left-[135px] absolute w-[80%] border-[1.5px] border-[#d3d3d3] border-dashed'></div>
                        </div>


                    </div>
                </div>
            </div>
            <div className='w-full flex mb-[15rem]    justify-center  items-center mt-[8rem] max-sm:mt-[4rem] mb-[5rem] flex-wrap '>
                <div className='relative bg-cover w-[100%]   flex-col flex items-center justify-center ' style={{ backgroundImage: `url(${aboutimg7})` }}>
                    <div className='text-center w-[100%] bg-[#000000a8]  py-12 flex flex-col items-center justify-center flex-wrap   max-sm:pb-[1rem] max-lg:pb-[1rem] '>

                        <div className="  w-[100%] mt-[3rem]  ">
                            <div className='flex flex-col  justify-center  items-center '>
                                <p className='color-cabiar text-[18px] font-[600]'>Nuestro Compromiso</p>
                                <p className=' text-[50px] font-[700] text-white max-lg:text-[30px] line-h-1' >Al Compartir esta oportunidad, crearás tu propia red de transporte<span className='color-cabiar'>.</span></p>
                                <p className='w-[70%] text-[1rem] text-white text-center text-[#6d6d6d] max-sm:text-[0.9rem]   max-sm:w-[95%]'>Ganarás puntos en cada viaje que tomes y en los viajes que tomen de las personas registradas en Cabiar a travéz de tu invitación.</p>
                            </div>
                        </div>
                        <div className='flex-wrap  px-[5rem] mt-[3rem] py-[3rem] mb-[-8rem] shadow-lg rounded-xl bg-white w-[70%] flex items-center justify-around'>
                            <div className='flex flex-col'>
                                <div className='flex items-center justify-center text-center'>
                                    <p className='text-[#2a2a2a] text-[50px] font-[700] mr-[1rem]'>6,178</p>
                                    <i className='fa-solid fa-plus color-cabiar' />
                                </div>
                                <p className='text-[#6d6d6d] text-[16px] text-center'>Nuestros coductores</p>
                            </div>
                            <div className='flex flex-col   text-center'>
                                <div className='flex items-center text-center justify-center'>
                                    <p className='text-[#2a2a2a] text-[50px] font-[700] mr-[1rem]'>7,632</p>
                                    <i className='fa-solid fa-plus color-cabiar' />
                                </div>
                                <p className='text-[#6d6d6d] text-[16px] text-center'>Nuestros pasajeros</p>
                            </div>
                            <div className='flex flex-col   text-center'>
                                <div className='flex items-center text-center justify-center'>
                                    <p className='text-[#2a2a2a] text-[50px] font-[700] mr-[1rem]'>38</p>
                                    <i className='fa-solid fa-plus color-cabiar' />
                                </div>
                                <p className='text-[#6d6d6d] text-[16px] text-center'>Paises proyectados</p>
                            </div>
                            <div className='flex flex-col '>
                                <div className='flex items-center text-center justify-center'>
                                    <p className='text-[#2a2a2a] text-[50px] font-[700] mr-[1rem]'>17,953</p>
                                    <i className='fa-solid fa-plus color-cabiar' />
                                </div>
                                <p className='text-[#6d6d6d] text-[16px] text-center'>Descargas de nuestra app</p>
                            </div>
                        </div>
                        {/* <img className='mt-[5rem]  mb-[-10rem] object-fit w-[900px] max-sm:w-[300px]   max-lg:w-[500px] ' src={aboutimg3} alt='' /> */}
                    </div>
                </div>
            </div>

            <div className='w-full mt-[15rem] bg-[#fff] khula flex flex-col justify-center items-center   '>
                <div className='flex items-center justify-evenly flex-wrap mx-[7rem] max-sm:mx-[1rem] max-lg:mx-[3rem] '>
                    <div className='   flex flex-col max-lg:w-full  '>
                        <p className='color-cabiar text-[18px] font-[600]'>Nuestro equipo </p>
                        <p className=' text-[50px] font-[700] text-[#000] line-h-title'>Conoce nuestro</p>
                        <p className=' text-[50px] font-[700] text-[#000] line-h-title'>equipo experto<span className='color-cabiar'>.</span></p>
                        <p className='mt-[2rem] w-[70%] text-[1rem]     text-[#6d6d6d] max-sm:text-[0.9rem]   max-sm:w-[95%]'>Un equipo lideres en la industria del trasporte esta a tus ordenes.</p>
                        {/* <button className='w-fit mt-[2rem] khula btn-send rounded-[7px] bg-[#213CC1] px-[4rem] py-[1.8rem] text-[#fff]'>Nuestro Equipo</button> */}
                    </div>
                    <div className='w-[50%] max-2xl:w-[70%] max-xl:w-[60%] max-lg:mt-[4rem]  flex items-center justify-evenly  relative flex-wrap'>
                        {/* <div className='w-[40%]   max-lg:w-[100%] relative  mt-[15rem] flex flex-col '>
                            <div className='absolute bottom-[-30px] w-[100%] '>
                                <img src={Team001} alt='' className='  w-[100%]  h-[450px] top-[50px] aboslute  max-lg:w-[450px] bg-[#000]   object-cover rounded-[12px]' />
                            </div>
                            <div className='relative w-[60%] khula self-end left-[10px] z-10 mx-[2rem] bg-[#2a2a2a] px-[30px] py-[15px] rounded-[14px] top-[15px] flex flex-col justify-end items-end'>
                                <h2 className='text-[#fff] text-[20px] font-bold '>Alfredo Caba</h2>
                                <h2 className='color-cabiar text-[18px] '>Diamante</h2>
                            </div>
                        </div> */}
                        <FotoEquipo
                            img={Team001}
                            title={`Alfredo Caba`}
                            subtitle={`Diamante`}
                            facebook={"https://www.facebook.com/freddy.caba.96caba?mibextid=LQQJ4d"}
                            youtube={"https://www.youtube.com/Cabiar"}
                            instagram={"https://instagram.com/eventos_caba?igshid=NTc4MTIwNjQ2YQ=="}
                        />

                        <FotoEquipo
                            img={Team002}
                            title={`Fernando Vargas`}
                            subtitle={`Diamante`}
                            facebook={"https://www.facebook.com/FernandoVargasGonzalez?mibextid=LQQJ4d"}
                            youtube={"https://youtu.be/UueH_hsUnDE"}
                            instagram={"https://instagram.com/fernandovargasgonzalez?igshid=NTc4MTIwNjQ2YQ=="}
                        />

                        <FotoEquipo
                            img={Team004}
                            title={`Henrry Veras`}
                            subtitle={`Diamante`}
                            facebook={"https://www.facebook.com/luzdary.veras?mibextid=LQQJ4d"}
                            youtube={"https://www.youtube.com/Cabiar"}
                            instagram={"https://instagram.com/el_veras007?igshid=NTc4MTIwNjQ2YQ=="}
                        />
                        <FotoEquipo
                            img={Team003}
                            title={`José A. Fernández`}
                            subtitle={`Diamante`}
                            facebook={"https://www.facebook.com/pacha.oro?mibextid=LQQJ4d"}
                            youtube={"https://www.youtube.com/Cabiar"}
                            instagram={"https://instagram.com/pacha_oro?igshid=NTc4MTIwNjQ2YQ=="}
                        />
                        {/* <div className='w-[40%] max-lg:w-[100%] max-lg:mt-8'>
                            <img src={Team002} alt='' className='w-[100%] h-[450px] max-lg:w-[450px] max-lg:h-full   bg-[#000] object-cover rounded-[12px]' />
                            <div className='aboslute'></div>
                        </div>
                        <div className='w-[40%] max-lg:w-[100%] max-lg:mt-8 mt-12'>
                            <img src={Team004} alt='' className='w-[100%] h-[450px] max-lg:w-[450px] max-lg:h-full   bg-[#000] object-cover rounded-[15px]' />
                            <div className='aboslute'></div>
                        </div>
                        <div className='w-[40%] max-lg:w-[100%] max-lg:mt-8 mt-12 relative'>
                            <img src={Team003} alt='' className='w-[100%] h-[450px] max-lg:w-[450px] max-lg:h-full   bg-[#000] object-cover rounded-[12px]' />
                            <div className='aboslute bottom-0'>
                                <h2 className='text-[#2d2d2] '>Alfredo Caba</h2>
                            </div>
                        </div> */}

                    </div>
                </div>
            </div>
            <FooterHead />
            <Footer />
        </div>
    )
}

export default About;