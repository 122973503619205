import React, { useState, useMemo, useCallback } from 'react'
import { BrowserRouter, HashRouter as Router, Routes, Route, Link } from "react-router-dom";
// import Login from './Components/Web/sb/Login';
import 'animate.css'
import './css/styles.css'
// import WebIndex from './Components/Web/sb/index.js';
// import RegistroContainer from './Components/Web/pages/Registro/RegistroContainer';
// import Dashboard from './Components/dashboard/page/Dashboard'
// import QueEsSarbbis from './Components/Web/sb/QueEsSarbbis'
// import ComoFunciona from './Components/Web/sb/ComoFunciona';
// import Soporte from './Components/Web/sb/Soporte';
import Home from './Components/Home/Home';
import './index.css';
import About from './Components/Home/About';
import Services from './Components/Home/Services';
import Contact from './Components/Home/Contact';
import RegistroCabiar from './Components/Home/RegistroCabiar';
import MapShare from './Components/Emergencias/MapShare';
import Politicas from './Components/Home/Politicas';
import Terminos from './Components/Home/Terminos';
import EliminarCuenta from './Components/Home/EliminarCuenta';
export const App = () => {

  return (
    <Router hashType="slash" forceRefresh={true}  >
      <Routes>
        <Route path="/" element={<Home />} /> 
        <Route path="/privacy-policies" element={<Politicas />} /> 
        <Route path="/terms-and-conditions" element={<Terminos />} /> 
        <Route path="/delete-account" element={<EliminarCuenta />} /> 
        <Route path="/about" element={<About />} /> 
        <Route path="/services" element={<Services />} /> 
        <Route path="/contact" element={<Contact />} /> 
        <Route path="/singup" element={<RegistroCabiar />} /> 
        <Route path="/share/:user/:name" element={<MapShare />} /> 
      </Routes>

    </Router>
  )
}

export default App // esto es para codesandbox